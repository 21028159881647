import React, {useEffect, useState} from 'react';
import {
    View,
    Text,
    StyleSheet,
    SafeAreaView,
    Image,
    TouchableOpacity,
    ScrollView,
    Linking,
    Dimensions
} from 'react-native';
import {useNavigation, useFocusEffect} from '@react-navigation/native';
import { Url} from '../global'

const ResultatScreen = () => {
    const navigation = useNavigation();

    const handleViewClick = () => {
        navigation.navigate('Etudiant');
    };

    // Données d'entrée
    const [items, setItems] = useState([]);


    useFocusEffect(
        React.useCallback(() => {
            fetch(Url+'api/all_matches/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => data && setItems(data))
                .catch((error) => console.error('There was an error fetching the data!', error));

            // Retournez une fonction de nettoyage si nécessaire
            return () => {};
        }, [])
    );


    const formatStatut = (statut) => {
        switch (statut) {
            case "annule":
                return "ANNULE";
            case "a_venir":
                return "A VENIR";
            case "en_cours":
                return "EN COURS";
            case "termine":
                return "TERMINE";
            default:
                return statut;
        }
    };


    const handleItemClick = (link) => {
        if (link) {
            // Vérifie si le lien commence par "http://" ou "https://", sinon ajoute "http://"
            if (!link.startsWith("http://") && !link.startsWith("https://")) {
                link = "http://" + link;
            }
            Linking.openURL(link);
        }
    };

    const formatMonth = (dateString) => {
        const months = ["JAN", "FEV", "MAR", "AVR", "MAI", "JUIN", "JUIL", "AOU", "SEP", "OCT", "NOV", "DEC"];

        // Analyser la chaîne de date au format "DD/MM/YYYY"
        const [day, monthNum, year] = dateString.split('/');
        const month = months[parseInt(monthNum, 10) - 1];

        return {day, month};
    };

    const renderIcon = (sport) => {
        switch (sport) {
            case "Rugby":
                return (
                    <Image
                        source={require('../assets/icons/sports/rugby.png')}
                        style={styles.sportIcon}
                        resizeMode="contain"
                    />
                );
            case "Foot":
                return (
                    <Image
                        source={require('../assets/icons/sports/foot.png')}
                        style={styles.sportIcon}
                        resizeMode="contain"
                    />
                );
            case "Foot américain":
                return (
                    <Image
                        source={require('../assets/icons/sports/foot_americain.png')}
                        style={styles.sportIcon}
                        resizeMode="contain"
                    />
                );
            default:
                return null;
        }
    };


    return (
        <SafeAreaView style={[styles.screenContainer, {backgroundColor: '#1B1835'}]}>
            {/* Header */}
            <View style={styles.header}>
                <TouchableOpacity onPress={handleViewClick}>
                    <Image
                        source={require('../assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            </View>

            {/* Content */}
            <ScrollView>
                <View style={styles.container}>
                    <Text style={styles.title}>Résultats</Text>
                    {items.map((item, index) => (
                        <TouchableOpacity
                            key={index}
                            onPress={() => handleItemClick(item.link)}
                            style={[styles.itemContainer, {backgroundColor: '#110E24'}]}
                        >
                            <View style={styles.leftContainer}>
                                <View style={styles.dateContainer}>
                                    <Text style={styles.dayText}>{formatMonth(item.date).day}</Text>
                                    <Text style={styles.monthText}>{formatMonth(item.date).month}</Text>
                                </View>
                            </View>
                            <View style={styles.rightContainer}>
                                <View style={styles.itemTextContainer}>
                                    <View style={styles.itemInfoContainer}>
                                        <View style={styles.titleContainer}>
                                            <Text style={styles.typeText}>{item.equipe1} - {item.equipe2}</Text>
                                        </View>
                                        <Text style={styles.nameText}>{item.score[0]} - {item.score[1]}</Text>
                                        <Text style={[
                                            styles.typeText,
                                            formatStatut(item.statut) === 'ANNULE' ? {color: '#9E0000'} : formatStatut(item.statut) === 'TERMINE' ? {color: '#18D92C'} : {color: 'white'}
                                        ]}>
                                            {formatStatut(item.statut)}
                                        </Text>
                                    </View>
                                    <View style={styles.sportIconContainer}>
                                        {renderIcon(item.sport)} {/* Afficher l'icône uniquement s'il y a un lien */}
                                    </View>
                                </View>
                            </View>
                        </TouchableOpacity>
                    ))}
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    leftContainer: {
        flex: 1,
        marginLeft: -25, // Adjust the marginLeft to center the left container
        alignItems: 'center', // Add this line to center the content inside the left container
    },
    dateContainer: {
        flexDirection: 'column', // Ajustement pour afficher les éléments l'un sous l'autre
        alignItems: 'center', // Aligner les éléments au centre horizontalement
        marginTop: 10,
        marginLeft: -10,
    },
    dayText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
    },
    monthText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        textTransform: 'uppercase', // Mettre en majuscule pour afficher les mois en majuscules
    },

    typeText: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', // Add this line to align the content vertically
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer: {
        alignItems: 'center',
    },
    sportIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        marginTop: 8,
    },
    title: {
        fontSize: 32,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,


    },
    itemContainer: {
        flexDirection: 'row',
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        alignItems: 'flex-start', // Use 'flex-start' to align content at the top
    },
    sportIconContainer: {
        marginTop: -10,
        alignSelf: 'center', // Align the sport icon vertically in the itemTextContainer
    },


});

export default ResultatScreen;
