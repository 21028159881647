import React, { useState } from 'react';
import { View, Text, StyleSheet, SafeAreaView, Image, TouchableOpacity, Dimensions, Linking, ScrollView, Modal, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useFocusEffect } from '@react-navigation/native';
import { Url} from './global'




const EvenementsScreen = () => {
    const navigation = useNavigation();
    const [items, setItems] = useState([]);
    const [itemsState, setItemsState] = useState(items.map(item => ((item.asso === "Celest") ? true : false)));


    useFocusEffect(
        React.useCallback(() => {
            fetch(Url+'api/evenements', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setItems(data);
                    // Mettre à jour itemsState ici
                    const newItemsState = data.map(item => item.asso === "Celest");
                    setItemsState(newItemsState);
                })
                .catch((error) => console.error('There was an error fetching the data!', error));

            return () => {};
        }, [])
    );







    const toggleHidden = (index) => {
        // Vérifier si l'item a "celest" comme asso
        if (items[index].asso === "Celest") {
            return; // Ne fait rien si c'est un item "celest"
        }

        const newItemsState = [...itemsState];
        newItemsState[index] = !newItemsState[index];
        setItemsState(newItemsState);
    };
    const formatMonth = (dateString) => {
        const months = ["JAN", "FEV", "MAR", "AVR", "MAI", "JUIN", "JUIL", "AOU", "SEP", "OCT", "NOV", "DEC"];

        // Analyser la chaîne de date au format "DD/MM/YYYY"
        const [day, monthNum, year] = dateString.split('/');
        const month = months[parseInt(monthNum, 10) - 1];

        return {day, month};
    };

    const renderIcon = (item) => {
        if (item.asso == "celest") {
            return (
                <Image
                    source={require('./assets/img/evenements/' + item.icon + '/icon.png')}
                    style={styles.Icon}
                    resizeMode="contain"
                />
            );

        }
        else {
            if(item.img || item.img_info2 || item.img_info1 || item.lien_info || item.lien_billeterie){
                return (
                    <Image
                        source={require('./assets/icons/header/fleche.png')}
                        style={[styles.arrowIcon,{ transform: [{ rotate: '90deg' }] }]}
                        resizeMode="contain"
                    />
                );
            }
        }
        return null;
    };
    const handleItemClick = (link) => {
        if (link) {
            // Vérifie si le lien commence par "http://" ou "https://", sinon ajoute "http://"
            if (!link.startsWith("http://") && !link.startsWith("https://")) {
                link = "http://" + link;
            }
            Linking.openURL(link);
        }
    };

    const handleViewClick = (screen) => {
        navigation.navigate(screen);
    };




    return (
        <SafeAreaView style={[styles.screenContainer, {backgroundColor: '#1B1835'}]}>
            <ScrollView>
                {/* Header */}
                <View style={styles.header}>

                </View>

                {/* Content */}
                <ScrollView>
                    <View style={styles.container}>
                        <Text style={styles.title}>Evénements</Text>
                        <Text style={styles.subtitle}>Retrouvez tous les prochaines événements assos ici !</Text>
                        <Text style={styles.subtitle}>Disponible à partir du 1er décembre</Text>
                        <View style={{ marginBottom:50,marginTop:20 }}>

                        </View>



                        {items.map((item, index) => (
                            <View key={index} style={[styles.itemContainer, { backgroundColor: item.couleur }]}>
                                {item.epingle ? (
                                    <View style={styles.circle}>
                                        <Image
                                            source={require('./assets/icons/header/epingle.png')}
                                            style={styles.epingleIcon}
                                            resizeMode="contain"
                                        />
                                    </View>
                                ) : null}

                                {item.billeterie_ouverte ? (
                                    <View style={styles.billeterie}>
                                        <Text style={styles.typeText2}>Billetterie ouverte</Text>
                                    </View>
                                ) : null}
                                <TouchableOpacity onPress={() => toggleHidden(index)}>
                                <View style={styles.barreHaut}>
                                    <View style={styles.leftContainer}>
                                        <View style={styles.dateContainer}>
                                            <Text style={styles.dayText}>{formatMonth(item.date).day}</Text>
                                            <Text style={styles.monthText}>{formatMonth(item.date).month}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rightContainer}>
                                        <View style={styles.itemTextContainer}>
                                            <View style={styles.itemInfoContainer}>
                                                <View style={styles.titleContainer}>
                                                    <Text style={styles.typeText}>{item.categorie}</Text>
                                                </View>
                                                <Text style={styles.nameText}>{item.nom}</Text>
                                                <Text style={styles.typeText}>{item.sous_titre}</Text>
                                            </View>
                                            {renderIcon(item)}
                                        </View>
                                    </View>
                                </View>



                                </TouchableOpacity>

                                {itemsState[index] ? (
                                    <View>
                                        {item.img ? (
                                            <View style={styles.imgContainer}>
                                                <Image
                                                    source={item.img}
                                                    resizeMode="cover"
                                                    style={styles.carouselItemImage}
                                                />
                                            </View>
                                        ) : null}

                                        {(item.img_info1 || item.img_info2 || item.lien_info || item.lien_billeterie) ? (
                                            <View style={styles.infoContainer}>
                                                <View style={styles.barreBas}>
                                                    {item.img_info1 ? (
                                                        <View style={styles.imgContainerInfo1}>
                                                            <Image
                                                                source={item.img_info1}
                                                                resizeMode="cover"
                                                                style={styles.carouselItemImage}
                                                            />
                                                        </View>
                                                    ) : null}
                                                    {item.img_info2 ? (
                                                        <View style={styles.imgContainerInfo1}>
                                                            <Image
                                                                source={item.img_info2}
                                                                resizeMode="cover"
                                                                style={styles.carouselItemImage}
                                                            />
                                                        </View>
                                                    ) : null}
                                                </View>
                                                <View style={styles.barreBas}>
                                                    {item.lien_info ? (
                                                        <TouchableOpacity
                                                            key={index}
                                                            onPress={() => handleItemClick(item.lien_info)}
                                                            style={[styles.itemButton, { backgroundColor: '#8ECAE6' }]}
                                                        >
                                                            <Text style={styles.nameText2}>Infos</Text>
                                                        </TouchableOpacity>
                                                    ) : null}
                                                    {item.lien_billeterie ? (
                                                        <TouchableOpacity
                                                            key={index}
                                                            onPress={() => handleItemClick(item.lien_billeterie)}
                                                            style={[styles.itemButton, { backgroundColor: '#F28C51' }]}
                                                        >
                                                            <Text style={styles.nameText2}>Billetterie</Text>
                                                        </TouchableOpacity>
                                                    ) : null}
                                                </View>
                                            </View>
                                        ) : null}
                                    </View>
                                ) : null}

                            </View>
                        ))}
                    </View>
                </ScrollView>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({

    text: {
        fontSize: 24,
        color: 'white',
    },
    screenContainer: {
        flex: 1,
        backgroundColor: '#1b1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
        padding: 20,  // Ajout d'un padding pour éviter que les éléments ne touchent les bords de l'écran
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 22,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    itemContainer: {
        width: '100%',  // Ajuster à 100% pour occuper toute la largeur disponible
        maxWidth: 400,  // Fixer une largeur maximale pour les grands écrans
        borderRadius: 10,
        marginVertical: 10,
        alignSelf: 'center',  // Centrer dans la vue parente
    },

    barreHaut: {
        flexDirection: 'row',
        padding: 10,

    },
    leftContainer: {
        flex: 1,
        marginLeft: -25,
    },
    dateContainer: {
        flexDirection: 'column', // Ajustement pour afficher les éléments l'un sous l'autre
        alignItems: 'center', // Aligner les éléments au centre horizontalement
        marginTop: 8,
        marginLeft: -10,
    },
    dayText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
    },
    monthText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        textTransform: 'uppercase', // Mettre en majuscule pour afficher les mois en majuscules
    },

    typeText: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },
    typeText2: {
        fontSize: 16,
        fontFamily: 'TripBold',
        color: 'white',
    },

    nameText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
    },

    nameText3: {
        fontSize: 38,
        fontFamily: 'TripBold',
        color: '#F18B51',
        marginBottom: 10,
        marginTop: 10,
        textAlign: 'center',
    },

    rightContainer: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between', // Pour aligner l'icône à droite du rectangle
        alignItems: 'center',
    },
    itemInfoContainer: {
        flexDirection: 'column',
        justifyContent: 'center', // Centrer le contenu verticalement
        alignItems: 'center', // Centrer le contenu horizontalement
    },


    titleContainer: {
        alignItems: 'center',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight: 10,
        alignSelf: "center"
    },
    epingleIcon: {
        width: 20,
        height: 20,

    },
    Icon: {
        width: 40,
        height: 40,
        marginRight: 10,
    },
    title: {
        fontSize: 34,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
        marginBottom: 5,
    },
    imgContainer: {
        width: '100%', // Take the full width of the parent view
        height: 300, // Set a maximum height of 200 for the container
        overflow: 'hidden',// To crop the image if it's larger than the container
        borderRadius: 12,
        padding: 1,
    },
    carouselItemImage: {
        width: '100%', // Take the full width of the container
        height: '100%', // Take the full height of the container (maximum 200)
    },
    nameText2: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
        alignSelf: "center",
    },
    infoContainer: {
        width: '100%', // Take the full width of the parent view
        padding: 10,
        overflow: 'hidden',
    },
    imgContainerInfo1: {
        width: 150, // Take the full width of the parent view
        height: 150, // Set a maximum height of 200 for the container
        overflow: 'hidden',// To crop the image if it's larger than the container
        marginHorizontal: 10,
        borderRadius: 4,


    },
    barreBas: {
        flexDirection: 'row',
        padding: 10,
        justifyContent: 'space-between',
        alignSelf: "center"

    },

    itemButton: {
        width: 150,
        borderRadius: 10,
        marginVertical: 5,
        marginHorizontal: 10,
    },
    circle: {
        width: 30, // La largeur du cercle
        height: 30, // La hauteur du cercle
        borderRadius: 15, // La moitié de la largeur/hauteur pour rendre le cercle rond
        backgroundColor: '#0D0B1C',
        position: 'absolute', // Positionnement absolu pour superposer le cercle
        top: -10, // Vous pouvez ajuster cette valeur pour positionner le cercle verticalement
        left: -10,
        flex: 1,
        justifyContent: 'center', // Aligner verticalement
        alignItems: 'center', // Aligner horizontalement
    },
    billeterie: {
        width: 140,
        height: 16,
        backgroundColor: '#0D0B1C',
        borderTopLeftRadius: 8, // Arrondir le coin supérieur gauche
        borderTopRightRadius: 8, // Arrondir le coin supérieur droit
        position: 'absolute', // Positionnement absolu pour superposer le cercle
        top: -16, // Vous pouvez ajuster cette valeur pour positionner le cercle verticalement
        right: 8,
        flex: 1,
        justifyContent: 'center', // Aligner verticalement
        alignItems: 'center', // Aligner horizontalement
    },

    berealButton: {
        width: 200,
        borderRadius: 20,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        marginBottom:15,
        backgroundColor: '#F18B51',
    }
});

export default EvenementsScreen;
