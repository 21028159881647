import {FlatList, SafeAreaView, StyleSheet, Text, TouchableOpacity, View, Image} from "react-native";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {useCallback, useEffect, useState} from "react";
import {v4 as uuidv4} from 'uuid';

const BerealScreen = () => {
  const navigation = useNavigation();

  const [bereals, setBereals] = useState([]);
  const [sortedBereals, setSortedBereals] = useState([]);
  const [hasPostedBereal, setHasPostedBereal] = useState(false);
  const [userId, setUserId] = useState(null);

  useFocusEffect(
    useCallback(() => {
      const setCookieUserId = async () => {
        const userId = localStorage.getItem('userId');

        if (!userId) {
          const newUserId = uuidv4();
          localStorage.setItem('userId', newUserId);
          setUserId(newUserId);
        } else {
          setUserId(userId);
        }
      }

      void setCookieUserId();

      fetch('http://127.0.0.1:8000/api/get_bereals')
        .then((response) => response.json())
        .then((json) => setBereals(json))
        .catch((error) => console.error(error));
    }, [])
  );

  useEffect(() => {
    setSortedBereals(bereals.sort((a) => {
        return a.user_id === userId ? -1 : 1;
      })
    );
  }, [bereals, userId]);

  useEffect(() => {
    if (sortedBereals[0] && sortedBereals[0].user_id === userId) {
      setHasPostedBereal(true);
    }
  }, [sortedBereals]);

  const renderItem = ({item, index}) => {
    return (<>
        {item.id === 'buttonSection' ? (
          <>
            {!hasPostedBereal && (
              <View style={styles.imageContainer}>
                <View style={styles.nobereal}>
                  <TouchableOpacity onPress={() => navigation.navigate('BerealCapture')} style={styles.berealButton} disabled={true}>
                    <Text style={styles.berealText}>Bientôt Disponible</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </>
        ) : (
          <View style={styles.imageContainer}>
            <Image source={{uri: item.image_url}} style={styles.image} blurRadius={hasPostedBereal ? 0 : 20}/>
            <Text style={styles.username}>{item.username}</Text>
          </View>
        )
        }
      </>
    )
  }

  return (
    <SafeAreaView style={[styles.screenContainer, {backgroundColor: '#1B1835'}]}>
      <View style={styles.container}>
        <FlatList
          data={[{id: 'buttonSection'}, ...sortedBereals]}
          renderItem={renderItem}
          keyExtractor={(item) => item.id.toString()}
          contentContainerStyle={styles.contentContainer}
        />
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
    text: {
      color: 'white',
      fontSize: 20,
      fontFamily: 'TripBold',
    },

    berealText: {
      color: '#1B1835',
      fontSize: 20,
      fontFamily: 'TripBold',
    },

    screenContainer: {
      flex: 1,
      backgroundColor: '#1b1835',
    },

    berealButton: {
      backgroundColor: '#ffffff',
      padding: 10,
      borderRadius: 10,
      alignItems: 'center',
    },

    container: {
      flex: 1,
      paddingHorizontal: 20, // Add padding to the container
    },

    contentContainer: {
      paddingVertical: 16, // Add vertical padding between items
    },

    imageContainer: {
      margin: 20, // Add margin between items
      overflow: 'hidden',
    },

    image: {
      width: '100%',
      aspectRatio: 3 / 4, // Set aspect ratio to 3:4
      borderRadius: 8,
    },

    username: {
      marginTop: 12, // Add space between the image and text
      color: 'white',
      fontSize: 20,
      fontFamily: 'TripBold',
    },

    nobereal: {
      backgroundColor: '#110e24',
      width: '100%',
      aspectRatio: 3 / 4, // Set aspect ratio to 3:4
      borderRadius: 8,
      flex: 1, // Ensure the container takes up the entire available space
      justifyContent: 'center', // Center vertically
      alignItems: 'center', // Center horizontally
    },

    blurImageContainer: {
      backgroundColor: '#110e24',
      width: '100%',
      aspectRatio: 3 / 4, // Set aspect ratio to 3:4
      borderRadius: 8,
      flex: 1, // Ensure the container takes up the entire available space
      justifyContent: 'center', // Center vertically
      alignItems: 'center', // Center horizontally
      overflow: 'hidden',
      position: 'relative',
      filter: 'blur(10px)',
      transform: 'scale(1.1)',
    },
  })
;

export default BerealScreen