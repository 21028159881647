import React, {useRef, useState, useEffect, useCallback} from 'react';
import {
    View,
    Text,
    StyleSheet,
    Image,
    TouchableOpacity,
    ScrollView,
    Dimensions,
    Linking,
    SafeAreaView
} from 'react-native';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import Cookies from "js-cookie";
import { Url} from '../global'

const RecrutementScreen = () => {
    const navigation = useNavigation();
    const containerRef = useRef(null);
    const [selectedElements, setSelectedElements] = useState([]);
    const [shouldCenterScrollView, setShouldCenterScrollView] = useState(false);

    const [selectedPostes, setSelectedPostes] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);



    const [items, setItems] = useState([]);


    useEffect(() => {
        fetch(Url+'api/all_recrutements/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => data && setItems(data))
            .catch((error) => console.error('There was an error fetching the data!', error));
    }, []); // Ajoutez un tableau de dépendances vide ici

    const handleViewClick = () => {
        navigation.navigate('Etudiant');
    };

    const handleRectanglePress = (element) => {
        // Check if the pressed element is in postes or categories list
        if (postes.includes(element)) {
            setSelectedPostes((prevSelectedPostes) =>
                prevSelectedPostes.includes(element)
                    ? prevSelectedPostes.filter((el) => el !== element)
                    : [...prevSelectedPostes, element]
            );
        } else if (categories.includes(element)) {
            setSelectedCategories((prevSelectedCategories) =>
                prevSelectedCategories.includes(element)
                    ? prevSelectedCategories.filter((el) => el !== element)
                    : [...prevSelectedCategories, element]
            );
        }
    };

    const filteredItems = items.filter((item) => {
        // If both selectedPostes and selectedCategories are empty, show all items
        if (selectedPostes.length === 0 && selectedCategories.length === 0) {
            return true;
        }

        // If selectedPostes are empty but selectedCategories are not, filter by categories
        if (selectedPostes.length === 0 && selectedCategories.length > 0) {
            return selectedCategories.includes(item.categorie);
        }

        // If selectedCategories are empty but selectedPostes are not, filter by postes
        if (selectedCategories.length === 0 && selectedPostes.length > 0) {
            return selectedPostes.includes(item.poste);
        }

        // Filter by both selectedPostes and selectedCategories
        return (
            selectedPostes.includes(item.poste) &&
            selectedCategories.includes(item.categorie)
        );
    });

    const checkShouldCenterScrollView = () => {
        if (containerRef.current && containerRef.current.scrollWidth <= Dimensions.get('window').width) {
            setShouldCenterScrollView(true);
        } else {
            setShouldCenterScrollView(false);
        }
    };

    const renderArrowIcon = (link) => {
        if (link) {
            return (
                <Image
                    source={require('../assets/icons/header/fleche.png')}
                    style={styles.arrowIcon}
                    resizeMode="contain"
                />
            );
        }
        return null;
    };

    useEffect(() => {
        checkShouldCenterScrollView();
    }, []);

    const postes = ['Membre', 'Respo Com', 'Respo Event', 'Respo Part', 'Chargé Com', 'Formateur'];

    const categories = [
        'Sport',
        'Technologie',
        'BDE / Clubs Ecole',
        'Citoyen',
        'Multimedia',
        'Art',
        'Business',
    ];

    const handleItemClick = (link) => {
        if (link) {
            // Vérifie si le lien commence par "http://" ou "https://", sinon ajoute "http://"
            if (!link.startsWith("http://") && !link.startsWith("https://")) {
                link = "http://" + link;
            }

            Linking.openURL(link).catch((err) => {
                console.error('Failed to open URL:', link, err);
            });
        }
    };

    const renderPostes = () => {
        return (
            <View>
                <Text style={[styles.sectionTitle, styles.sectionTitleMargin]}>Postes</Text>
                <ScrollView
                    contentContainerStyle={[styles.scrollViewContent, shouldCenterScrollView && styles.centerScrollView]}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    onContentSizeChange={checkShouldCenterScrollView}
                >
                    <View style={styles.container} ref={containerRef}>
                        {postes.map((poste) => (
                            <TouchableOpacity
                                key={poste}
                                onPress={() => handleRectanglePress(poste)}
                                style={[
                                    styles.rectangle,
                                    selectedPostes.includes(poste) && styles.selectedRectangle,
                                ]}
                            >
                                <Text style={styles.elementText}>{poste}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                </ScrollView>
            </View>
        );
    };

    const renderCategories = () => {
        return (
            <View>
                <Text style={[styles.sectionTitle, styles.sectionTitleMargin]}>Categories</Text>
                <ScrollView
                    contentContainerStyle={[styles.scrollViewContent, shouldCenterScrollView && styles.centerScrollView]}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    onContentSizeChange={checkShouldCenterScrollView}
                >
                    <View style={styles.container} ref={containerRef}>
                        {categories.map((category) => (
                            <TouchableOpacity
                                key={category}
                                onPress={() => handleRectanglePress(category)}
                                style={[
                                    styles.rectangle,
                                    selectedCategories.includes(category) && styles.selectedRectangle,
                                ]}
                            >
                                <Text style={styles.elementText}>{category}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                </ScrollView>
            </View>
        );
    };
    ""

    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
        <ScrollView>
        <View >
            {/* Header */}
            <View style={styles.header}>
                <TouchableOpacity onPress={handleViewClick}>
                    <Image
                        source={require('../assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
                <Text style={styles.title}>Recrutement</Text>

            </View>

            {/* Content */}

            <View style={styles.contentContainer}>
                <Text style={styles.subtitle}>CECI EST UNE DEMO</Text>
                <Text style={styles.subtitle}>Les annonces sont fictives</Text>

                    {/* Postes */}
                {renderPostes()}
                {renderCategories()}

                    {/* Offres */}
                    <View style={styles.container2}>

                        <Text style={styles.title}>Offres</Text>
                        {filteredItems.map((item, index) => (
                            <TouchableOpacity
                                key={index}
                                onPress={() => handleItemClick(item.link)}
                                style={[styles.itemContainer2, { backgroundColor: '#110E24' }]}
                            >
                                <View style={styles.leftContainer2}>
                                    <Image
                                        source={item.logo}
                                        style={styles.logoImg}
                                        resizeMode="contain"
                                    />
                                </View>
                                <View style={styles.rightContainer2}>
                                    <View style={styles.itemTextContainer2}>
                                        <View style={styles.itemInfoContainer2}>
                                            <View style={styles.titleContainer2}>
                                                <Text style={styles.typeText2}>{item.association}</Text>
                                            </View>
                                            <Text style={styles.nameText2}>{item.poste}</Text>
                                            <Text style={styles.typeText2}>{item.deadline}</Text>
                                        </View>
                                        {renderArrowIcon(item.link)}
                                    </View>
                                </View>
                            </TouchableOpacity>
                        ))}
                    </View>

            </View>

        </View>
        </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    scrollViewContainer: {
        flexGrow: 1,
        marginBottom:-120,
    },
    centerScrollView: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    scrollViewContent: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    rectangle: {
        backgroundColor: '#110E24',
        height: 40,
        borderRadius: 25,
        marginVertical: 10,
        marginHorizontal: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    elementText: {
        fontSize: 20,
        fontFamily: 'TripBold',
        color: 'white',
        marginRight: 8,
        marginLeft: 8,
    },
    title: {
        fontSize: 32,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        flex: 1,
    },
    sectionTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
    },
    sportIconContainer: {
        marginTop: -10,
        alignSelf: 'center', // Align the sport icon vertically in the itemTextContainer
    },
    sportIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        marginTop: 8,
    },


    leftContainer: {
        flex: 1,
        marginLeft: -25, // Adjust the marginLeft to center the left container
        alignItems: 'center', // Add this line to center the content inside the left container
    },


    typeText: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', // Add this line to align the content vertically
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer: {
        alignItems: 'center',
    },

    subtitle: {
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
    },
    itemContainer: {
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        alignItems: 'flex-start', // Use 'flex-start' to align content at the top
    },


    container2: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    itemContainer2: {
        flexDirection: 'row',
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
    },
    leftContainer2: {
        flex: 1,
        marginLeft: -25,
    },


    typeText2: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText2: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer2: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer2: {
        flexDirection: 'row',
        justifyContent: 'space-between', // Pour aligner l'icône à droite du rectangle
        alignItems: 'center',
    },
    itemInfoContainer2: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer2: {
        alignItems: 'center',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight: 10,
        marginTop: 10,
    },
    logoImg: {
        width: 80,
        height: 80,
        marginLeft: 25,
    },
    selectedRectangle: {
        backgroundColor: '#8ECAE6', // Light blue background for selected items
    },


});

export default RecrutementScreen;
