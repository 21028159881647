import React, { useState,useEffect } from 'react';
import {
    View,
    Text,
    StyleSheet,
    SafeAreaView,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions,
    TextInput,
    Picker
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

const ModifierProfilScreen = () => {
    const navigation = useNavigation();

    const handleViewClick = (screen) => {
        navigation.navigate('Profil');
    };

    const [pseudo, setPseudo] = useState('');
    const [ecole, setEcole] = useState(''); // Changer la valeur initiale à une chaîne vide
    const [annee, setAnnee] = useState(''); // Changer la valeur initiale à une chaîne vide

    const listeEcole = ["ESILV", "IIM", "EMLV"];
    const listeAnnee = ["A1", "A2", "A3","A4","A5"];
    const user = { ecole: "ESILV", annee: "A5", pseudo: "Bob" };



    const handleLogin = () => {
        // Ici, vous pouvez implémenter la logique de connexion en utilisant l'email et le mot de passe saisis.
        // Par exemple, vous pouvez appeler une API pour vérifier les informations de connexion.
        // Pour cet exemple, nous allons simplement afficher les valeurs dans la console.
        console.log("Pseudo:", pseudo);
        console.log("Ecole:", ecole);
        console.log("Annee:", annee);
    };

    useEffect(() => {
        setPseudo(user.pseudo)
        setEcole(user.ecole);
        setAnnee(user.annee);
    }, []);

    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
            {/* Header */}
            <View style={styles.header}>
                <TouchableOpacity onPress={handleViewClick}>
                    <Image
                        source={require('../assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
                <Text style={styles.title}>Modifier Profil</Text>
                <Text style={styles.subtitle}>DEMO</Text>
            </View>

            <View style={styles.container}>
                <Text style={styles.subtitle}>Pseudo</Text>

                <TextInput
                    style={[styles.input, { textAlign: 'center', borderRadius: 20 }]}
                    placeholder={pseudo}
                    onChangeText={(text) => setPseudo(text)}
                    value={pseudo} // Use the 'pseudo' state for the value
                    placeholderTextColor="#9A8FAF"
                />

                {/* Zone de saisie du mot de passe */}
                <Picker
                    style={[styles.input, { textAlign: 'center', borderRadius: 20 }]}
                    selectedValue={ecole}
                    onValueChange={(itemValue, itemIndex) => setEcole(itemValue)}
                >
                    {listeEcole.map((ecole) => (
                        <Picker.Item key={ecole} label={ecole} value={ecole} />
                    ))}
                </Picker>

                <Picker
                    style={[styles.input, { textAlign: 'center', borderRadius: 20 }]}
                    selectedValue={annee}
                    onValueChange={(itemValue, itemIndex) => setAnnee(itemValue)}
                >
                    {listeAnnee.map((annee) => (
                        <Picker.Item key={annee} label={annee} value={annee} />
                    ))}
                </Picker>

                {/* Bouton de connexion personnalisé */}
                <TouchableOpacity
                    style={[styles.buttonConnexion, { backgroundColor: '#110E24' }]}
                    onPress={handleLogin}
                >
                    <Text style={styles.buttonText}>Modifier</Text>
                </TouchableOpacity>
            </View>


        </SafeAreaView>

    );
};

const styles = StyleSheet.create({

    itemContainer: {
        flexDirection: 'row',
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
    },
    leftContainer: {
        flex: 1,
        marginLeft:-25,
    },
    dateContainer: {
        flexDirection: 'column', // Ajustement pour afficher les éléments l'un sous l'autre
        alignItems: 'center', // Aligner les éléments au centre horizontalement
        marginBottom: 5,
        marginLeft:-10,
    },
    dayText: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
    },
    monthText: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
        textTransform: 'uppercase', // Mettre en majuscule pour afficher les mois en majuscules
    },

    typeText: {
        fontSize: 13,
        fontFamily: 'MontserratRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 2,
        marginLeft : -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between', // Pour aligner l'icône à droite du rectangle
        alignItems: 'center',
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer: {
        alignItems: 'center',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight:10,
        marginTop:10,
    },

    subtitle: {
        fontSize: 18,
        fontFamily: 'MontserratRegular',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,


    },

    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{ rotate: '180deg' }],
    },
    headerTitle: {
        fontSize: 20,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
        padding: 20,
    },
    title: {
        fontSize: 28,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        flex: 1,
    },
    input: {
        height: 40,
        width: Dimensions.get('window').width < 350 ? '100%' : 350,
        borderColor: '#15132A',
        borderWidth: 1,
        marginBottom: 20,
        paddingHorizontal: 10,
        color: 'white',
        backgroundColor: '#110E24',
        fontSize: 18,
    },
    buttonConnexion: {
        borderRadius: 20,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        width: 200,
    },
    buttonText: {
        color: 'white',
        fontSize: 18,
        fontFamily: 'MontserratBold', // Remplacez 'YourCustomFont' par le nom de la police que vous souhaitez utiliser
    },
});

export default ModifierProfilScreen;
