import React, { useState,useEffect } from 'react';
import {View, Text, TextInput, TouchableOpacity, StyleSheet, SafeAreaView, Dimensions, Image} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Url} from './global'



const LoginScreen = () => {
    const navigation = useNavigation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']); // Ajoutez removeCookie

    useEffect(() => {
        if (Cookies.get('accessToken')) {
            console.log("Hey")
            setTimeout(() => navigation.navigate("Evenements"), 100);
        }
    }, [navigation]);



    const handleLogin = () => {
        // Prepare data
        const data = {
            username: email,
            password: password,
        };

        // Make a POST request to the Django backend
        fetch(Url+`api/token/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Invalid credentials');
                }
                return response.json();
            })
            .then(data => {
                // Successfully received the token
                const { access_token } = data;

                // Store the access token in a secure cookie
                Cookies.set('accessToken', access_token, { secure: true });
                window.location.reload();

                // Navigate to the next page
                navigation.navigate('Evenements');
            })
            .catch(error => {
                // Handle error (e.g., invalid credentials)
                setError('Identifiants incorrects');
                console.error('An error occurred during login:', error);
            });
    };

    const handleInviteClick = () => {
        navigation.navigate("Evenements");
    };

    const handleBack = () => {
        navigation.navigate("ChoixConnexion");
    };

    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
            {/* Content */}
            <View style={styles.header}>
                <TouchableOpacity onPress={handleBack}>
                    <Image
                        source={require('./assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            </View>
            <View style={styles.container}>
                {/* Titre de la page */}
                <Text style={styles.title}>Login</Text>

                {/* Zone de saisie de l'email */}
                <TextInput
                    style={[styles.input, { textAlign: 'center', borderRadius: 20 }]} // Ajouter borderRadius: 20 ici
                    placeholder="Pseudo"
                    onChangeText={(text) => setEmail(text)}
                    value={email}
                    placeholderTextColor="#9A8FAF"
                />

                {/* Zone de saisie du mot de passe */}
                <TextInput
                    style={[styles.input, { textAlign: 'center', borderRadius: 20 }]} // Ajouter borderRadius: 20 ici
                    placeholder="Mot de passe"
                    onChangeText={(text) => setPassword(text)}
                    value={password}
                    secureTextEntry
                    placeholderTextColor="#9A8FAF"
                    onSubmitEditing={handleLogin}
                />
                {error && <Text style={styles.errorMessage}>{error}</Text>}
                {/* Bouton de connexion personnalisé */}
                <TouchableOpacity
                    style={[styles.buttonConnexion, { backgroundColor: '#8DC9E6' }]} // Changer la couleur du bouton à #110E24
                    onPress={handleLogin}
                >
                    <Text style={styles.buttonText}>Se connecter</Text>

                </TouchableOpacity>

                <TouchableOpacity
                    style={[styles.buttonConnexion, { backgroundColor: '#110E24' }]} // Changer la couleur du bouton à #110E24
                    onPress={handleInviteClick}
                >
                    <Text style={styles.buttonText}>Accès Invité</Text>

                </TouchableOpacity>


            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{ rotate: '180deg' }],
    },
    headerTitle: {
        fontSize: 20,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
        padding: 20,
    },
    title: {
        fontSize: 28,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginBottom: 20,
    },
    input: {
        height: 40,
        width: Dimensions.get('window').width < 350 ? '100%' : 350,
        borderColor: '#15132A',
        borderWidth: 1,
        marginBottom: 20,
        paddingHorizontal: 10,
        color: 'white',
        backgroundColor: '#110E24',
        fontSize: 18,
    },
    buttonConnexion: {
        borderRadius: 20,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        width: 200,
        marginBottom:15,
    },

    buttonText: {
        color: 'white',
        fontSize: 18,
        fontFamily: 'MontserratBold', // Remplacez 'YourCustomFont' par le nom de la police que vous souhaitez utiliser
    },
    errorMessage: {
        color: 'red',
        marginBottom: 20,
    },
});

export default LoginScreen;
