import React, {useEffect, useState} from 'react';
import {
    View,
    Text,
    StyleSheet,
    SafeAreaView,
    Image,
    TouchableOpacity,
    ScrollView,
    Linking,
    Dimensions
} from 'react-native';
import {useNavigation, useFocusEffect} from '@react-navigation/native';
import { Url} from '../global'

const FAQAssoScreen = () => {
    const navigation = useNavigation();

    const handleViewClick = () => {
        navigation.navigate('FAQ');
    };
    const [openItemIndex, setOpenItemIndex] = useState(null);


    // Données d'entrée
    const items = [{name:"J’ai perdu ma carte du Pôle, que faire ?",reponse:"Envoies un mail au service financier compta_étudiante@devinci.fr pour signaler la perte de ton badge. Il faudra ensuite payer 25€ pour en commander une nouvelle."},
        {name:"Où est-ce que je peux manger au Pôle ?",reponse:"Il y a 3 espacesavec des distributeurs et des micro-ondes au Pôle : le Foyer (en RH), le Lounge (au bout de la RH) et le Grill (en RB). \n" +
                "Il y a aussi une Caféteria au campus de l'Arche avec des distributeurs et des micro-ondes.\n" +
                "Tu pourras trouver de quoi te régaler autour du Pôle (Monoprix, Jil's, Mongoo) et au Westfield des 4 Temps."},
        {name:"Quels sont les horaires du Pôle ?",reponse:"Le Pôle est ouvert de 7h30 à 21h du lundi au vendredi et de 7h30 à 13h le samedi."},
        {name:"Comment accéder à la salle de sport ?",reponse:"Rends toi directement dans la salle (en rue Basse, tout au bout à gauche) pour t'inscrire avec 2 photos d'identité et la de renseignement remplie.\n" +
                "(lien fiche renseignement : https://devinci.sharepoint.com/sites/VAS/SitePages/Salle-de-sport.aspx )\n"},
        {name:"Quels sont les horaires de la salle de sport ?",reponse:"La salle de sport est ouverte : \n" +
                "les lundi et mercredi de 12h15 à 14h et de 17h30 à 19h15\n" +
                "les mardi et vendredi de 11h30 à 15h et de 17h30 à 19h15"}



    ];



    return (
        <SafeAreaView style={[styles.screenContainer, {backgroundColor: '#1B1835'}]}>
            {/* Header */}
            <View style={styles.header}>
                <TouchableOpacity onPress={handleViewClick}>
                    <Image
                        source={require('../assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            </View>

            {/* Content */}
            <ScrollView>
                <View style={styles.container}>
                    <Text style={styles.title}>FAQ - Pôle</Text>
                    {items.map((item, index) => (
                        <TouchableOpacity
                            key={index}
                            style={[styles.itemContainer, {backgroundColor: '#110E24'}]}
                            onPress={() => {
                                // Toggle the open state
                                if (openItemIndex === index) {
                                    setOpenItemIndex(null);
                                } else {
                                    setOpenItemIndex(index);
                                }
                            }}
                        >
                            <View style={styles.questionContainer}>
                                <Text style={styles.simplifiedText}>{item.name}</Text>
                                <Image
                                    source={require('../assets/icons/plus.png')}
                                    style={styles.arrowIcon}
                                    resizeMode="contain"
                                />
                            </View>

                            {/* Conditionally render the response if this item is open */}
                            {openItemIndex === index && (
                                <Text style={styles.simplifiedText2}>{item.reponse}</Text>
                            )}
                        </TouchableOpacity>
                    ))}
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight: 10,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    leftContainer: {
        flex: 1,
        marginLeft: -25, // Adjust the marginLeft to center the left container
        alignItems: 'center', // Add this line to center the content inside the left container
    },
    dateContainer: {
        flexDirection: 'column', // Ajustement pour afficher les éléments l'un sous l'autre
        alignItems: 'center', // Aligner les éléments au centre horizontalement
        marginTop: 10,
        marginLeft: -10,
    },
    dayText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
    },
    monthText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        textTransform: 'uppercase', // Mettre en majuscule pour afficher les mois en majuscules
    },

    typeText: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 1, // Add this
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1, // Add this
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginHorizontal:5,
    },
    titleContainer: {
        alignItems: 'center',
    },
    sportIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        marginTop: 8,
    },
    title: {
        fontSize: 32,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
        flexShrink: 1 // Add this line
    },
    itemContainer: {
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        alignItems: 'center', // Use 'flex-start' to align content at the top
    },
    sportIconContainer: {
        marginTop: -10,
        alignSelf: 'center', // Align the sport icon vertically in the itemTextContainer
    },
    simplifiedContainer: {
        padding: 5,
    },
    simplifiedText: {
        width: '90%',
        fontSize: 24,
        fontFamily: 'TripRegular',
        color: 'white',
    },
    simplifiedText2: {
        paddingTop:15,
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
    },
    questionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    answerContainer: {
        marginTop: 10, // Add some margin for visual separation
    },


});

export default FAQAssoScreen;
