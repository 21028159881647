import React, {useEffect, useState} from 'react';
import {
    View,
    Text,
    StyleSheet,
    SafeAreaView,
    Image,
    TouchableOpacity,
    ScrollView,
    Linking,
    Dimensions
} from 'react-native';
import {useNavigation, useFocusEffect} from '@react-navigation/native';
import { Url} from '../global'

const FAQAssoScreen = () => {
    const navigation = useNavigation();

    const handleViewClick = () => {
        navigation.navigate('FAQ');
    };
    const [openItemIndex, setOpenItemIndex] = useState(null);


    // Données d'entrée
    const items = [{name:"Comment intégrer une association ?",reponse:"Chaque début d'année les associations ouvrent leur recrutements. C'est à ce moment que tu peux postuler (+ d'infos sur leurs réseaux). Certaines associations gardent leur recrutement ouvert toute l'année, n'hésite pas à leur demander si tu es interessé.e."},
        {name:"Y a t il une limite de nombre d’associations que l’on peut rejoindre ?",reponse:"Oui !\n\nTu ne peux être que dans 3 associations au cours d'une année. Si tu souhaites en rejoindre une autre, il faudra que tu en quittes une."},
        {name:"Est-ce que je peux créer ma propre association ?",reponse:"Bien sûr ! \n\nSi aucune des associations ne te plait, tu peux déposer un dossier complet à la Vie Associative. Attention, toutes les demandes de création d'associations ne sont pas acceptées, ton projet doit être solide."},
        {name:"Est-ce dur de rejoindre une association ?",reponse:"Tout dépend, certaines associations sont très demandées et leur places sont limités. Mais tu trouveras de nombreuses associations qui seront ravies de t'accueillir. Avec les nombreuses associations, il y a de la place pour tout le monde !"},
        {name:"Peut-on rejoindre une association en cours d’année ?",reponse:"Cela dépend des associations. Certaines réouvrent leur recrutement en milieu d'année ou le laisse ouvert toute l'année.\n\nSi tu es interessé.e par une association n'hésite pas à les contacter pour leur demander."},
        {name:"Où trouver la liste de toutes les associations ?",reponse:"Sur ton portail étudiant !\n\nOnglet Vie étudiante et campus > La Vie Associative > Les associations"},

    ];



    return (
        <SafeAreaView style={[styles.screenContainer, {backgroundColor: '#1B1835'}]}>
            {/* Header */}
            <View style={styles.header}>
                <TouchableOpacity onPress={handleViewClick}>
                    <Image
                        source={require('../assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            </View>

            {/* Content */}
            <ScrollView>
                <View style={styles.container}>
                    <Text style={styles.title}>FAQ - Associations</Text>
                    {items.map((item, index) => (
                        <TouchableOpacity
                            key={index}
                            style={[styles.itemContainer, {backgroundColor: '#110E24'}]}
                            onPress={() => {
                                // Toggle the open state
                                if (openItemIndex === index) {
                                    setOpenItemIndex(null);
                                } else {
                                    setOpenItemIndex(index);
                                }
                            }}
                        >
                            <View style={styles.questionContainer}>
                                <Text style={styles.simplifiedText}>{item.name}</Text>
                                <Image
                                    source={require('../assets/icons/plus.png')}
                                    style={styles.arrowIcon}
                                    resizeMode="contain"
                                />
                            </View>

                            {/* Conditionally render the response if this item is open */}
                            {openItemIndex === index && (
                                <Text style={styles.simplifiedText2}>{item.reponse}</Text>
                            )}
                        </TouchableOpacity>
                    ))}
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight: 10,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    leftContainer: {
        flex: 1,
        marginLeft: -25, // Adjust the marginLeft to center the left container
        alignItems: 'center', // Add this line to center the content inside the left container
    },
    dateContainer: {
        flexDirection: 'column', // Ajustement pour afficher les éléments l'un sous l'autre
        alignItems: 'center', // Aligner les éléments au centre horizontalement
        marginTop: 10,
        marginLeft: -10,
    },
    dayText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
    },
    monthText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        textTransform: 'uppercase', // Mettre en majuscule pour afficher les mois en majuscules
    },

    typeText: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 1, // Add this
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1, // Add this
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginHorizontal:5,
    },
    titleContainer: {
        alignItems: 'center',
    },
    sportIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        marginTop: 8,
    },
    title: {
        fontSize: 32,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
        flexShrink: 1 // Add this line
    },
    itemContainer: {
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        alignItems: 'center', // Use 'flex-start' to align content at the top
    },
    sportIconContainer: {
        marginTop: -10,
        alignSelf: 'center', // Align the sport icon vertically in the itemTextContainer
    },
    simplifiedContainer: {
        padding: 5,
    },
    simplifiedText: {
        width: '90%',
        fontSize: 24,
        fontFamily: 'TripRegular',
        color: 'white',
    },
    simplifiedText2: {
        paddingTop:15,
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
    },
    questionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    answerContainer: {
        marginTop: 10, // Add some margin for visual separation
    },


});

export default FAQAssoScreen;
