import React, { useState,useEffect } from 'react';
import {View, Text, TextInput, TouchableOpacity, StyleSheet, SafeAreaView, Dimensions, Linking} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import AsyncStorage from '@react-native-async-storage/async-storage';




const ProfilInviteScreen = () => {
    const navigation = useNavigation();
    const forms = "https://forms.gle/3PGLamKoXBoXdBgx8"


    const handleLoginClick = () => {
        navigation.navigate("Login");
    };

    const handleItemClick = (link) => {
        if (link) {
            // Vérifie si le lien commence par "http://" ou "https://", sinon ajoute "http://"
            if (!link.startsWith("http://") && !link.startsWith("https://")) {
                link = "http://" + link;
            }

            Linking.openURL(link).catch((err) => {
                console.error('Failed to open URL:', link, err);
            });
        }
    };

    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
            {/* Content */}
            <View style={styles.container}>
                {/* Titre de la page */}
                <Text style={styles.title}>Profil</Text>
                <Text style={styles.subtitle}>Bientôt disponible</Text>
                <Text style={styles.subtitle2}>Une remarque, une idée pour le mandat ?</Text>
                <Text style={styles.subtitle2}>Rempli le formulaire ci-dessous !</Text>
                <TouchableOpacity
                    style={[styles.buttonConnexion, { backgroundColor: '#110E24' }]} // Changer la couleur du bouton à #110E24
                    onPress={() => handleItemClick(forms)}

                >
                    <Text style={styles.buttonText}>Formulaire</Text>

                </TouchableOpacity>
            </View>

        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{ rotate: '180deg' }],
    },
    headerTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
        marginBottom:10,
    },
    container2: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    title: {
        fontSize: 34,
        fontFamily: 'TripBold',
        color: 'white',
    },
    input: {
        height: 40,
        width: Dimensions.get('window').width < 350 ? '100%' : 350,
        borderColor: '#15132A',
        borderWidth: 1,
        marginBottom: 20,
        paddingHorizontal: 10,
        color: 'white',
        backgroundColor: '#110E24',
        fontSize: 18,
    },
    buttonConnexion: {
        borderRadius: 20,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        width: 200,
        marginBottom:15,
    },

    buttonText: {
        color: 'white',
        fontSize: 22,
        fontFamily: 'TripBold', // Remplacez 'YourCustomFont' par le nom de la police que vous souhaitez utiliser
    },
    errorMessage: {
        color: 'red',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 24,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
        marginBottom: 80,
    },
    subtitle2: {
        fontSize: 24,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
        marginBottom: 5,

    },
});

export default ProfilInviteScreen;
