import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';
import { useNavigation, useIsFocused } from '@react-navigation/native';

// Import your icons
import etudiantIcon from './assets/icons/footer/etudiant.png';
import etudiantIconWhite from './assets/icons/footer/etudiant_blanc.png';
import recapIcon from './assets/icons/footer/recap.png';
import recapIconWhite from './assets/icons/footer/recap_blanc.png';
import evenementsIcon from './assets/icons/footer/evenements.png';
import evenementsIconWhite from './assets/icons/footer/evenements_blanc.png';

import profilIcon from './assets/icons/footer/profil.png';
import creelIcon from './assets/icons/footer/creel.png';
import celestIcon from './assets/icons/footer/celest.png';

const Footer = () => {


    const navigation = useNavigation();
    const isFocused = useIsFocused(); // To check if the screen is focused
    const [currentPage, setCurrentPage] = useState('Liste');
    const backgroundColor = (currentPage === 'Liste' || currentPage === 'Liste2') ? 'black' : '#110E24';


    // Listen for navigation changes to update the currentPage state
    useEffect(() => {
        const unsubscribe = navigation.addListener('state', (event) => {
            const currentRouteName = event.data.state.routes[event.data.state.index].name;
            setCurrentPage(currentRouteName);
        });

        return unsubscribe;
    }, [navigation]);

    const pages = [
        { name: 'Etudiant', icon: etudiantIcon, iconWhite: etudiantIconWhite },
        { name: 'Evenements', icon: evenementsIcon, iconWhite: evenementsIconWhite },
        { name: 'Liste', icon: celestIcon, iconWhite: celestIcon},
        { name: 'Recap', icon: recapIcon, iconWhite: recapIconWhite },
        { name: 'CRéel', icon: profilIcon, iconWhite: creelIcon },
    ];

    function findListName(name) {
        for (const key in iconMapping) {
            if (iconMapping[key].includes(name)) {
                return key;
            }
        }
        return null; // Return null if the name is not found in any list
    }

    const iconMapping = {
        Etudiant: ['Etudiant', 'Echeances','Disponibilites','Recrutement','Resultat'],
        Evenements: ['Evenements'],
        Liste: ['Liste'],
        Recap: ['Recap'],
        Profil: ['Profil','ModifierProfil','Billets','Promos','ProfilInvite'],
        // Vous pouvez ajouter d'autres icônes et leurs pages associées ici
    };
    const currentPageIcons = findListName(currentPage)

    const handleTabPress = (pageName) => {
        setCurrentPage(pageName);
        navigation.navigate(pageName);
    };

    if (currentPage === 'Login') {
        return null;
    }

    return (
        <View style={[styles.container, { backgroundColor }]}>
            {pages.map((page, index) => (
                <TouchableOpacity
                    key={page.name}
                    style={styles.tab}
                    onPress={() => handleTabPress(page.name)}
                >
                    <Image
                        source={currentPageIcons === page.name ? page.icon : page.iconWhite}
                        style={page.name === 'Liste' ? styles.middleIcon : styles.icon}
                    />
                    {/* Condition to not display the text for 'Liste' tab */}
                    {page.name !== 'Liste' && (
                        <Text style={[styles.tabText, { color: currentPage === page.name ? 'white' : '#999' }]}>
                            {page.name}
                        </Text>
                    )}
                </TouchableOpacity>
            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#110E24',
    },
    tab: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 10,
    },
    icon: {
        width: 24,
        height: 24,
        resizeMode: 'contain',
    },
    middleIcon: {
        width: 60,  // Adjust the size based on your needs
        height: 60, // Adjust the size based on your needs
        resizeMode: 'contain',
        top:-10,
    },
    tabText: {
        fontSize: 12,
        marginTop: 5,
    },
});

export default Footer;
