import React, { useState,useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, SafeAreaView, Dimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Url} from './global'



const ChoixConnexionScreen = () => {
    const navigation = useNavigation();



    useEffect(() => {
        if (Cookies.get('accessToken')) {
            setTimeout(() => navigation.navigate("Evenements"), 100);
        }
    }, [navigation]);





    const handleLoginClick = () => {
        navigation.navigate("Login");
    };

    const handleRegisterClick = () => {
        navigation.navigate("Register");
    };

    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
            {/* Content */}
            <View style={styles.container}>
                {/* Titre de la page */}

                <TouchableOpacity
                    style={[styles.buttonConnexion, { backgroundColor: '#8DC9E6' }]} // Changer la couleur du bouton à #110E24
                    onPress={handleLoginClick}
                >
                    <Text style={styles.buttonText}>Connexion</Text>

                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.buttonConnexion, { backgroundColor: '#110E24' }]} // Changer la couleur du bouton à #110E24
                    onPress={handleRegisterClick}
                >
                    <Text style={styles.buttonText}>Inscription</Text>

                </TouchableOpacity>

            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{ rotate: '180deg' }],
    },
    headerTitle: {
        fontSize: 20,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
        padding: 20,
    },
    title: {
        fontSize: 28,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginBottom: 20,
    },
    input: {
        height: 40,
        width: Dimensions.get('window').width < 350 ? '100%' : 350,
        borderColor: '#15132A',
        borderWidth: 1,
        marginBottom: 20,
        paddingHorizontal: 10,
        color: 'white',
        backgroundColor: '#110E24',
        fontSize: 18,
    },
    buttonConnexion: {
        borderRadius: 20,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        width: 200,
        marginBottom:15,
    },

    buttonText: {
        color: 'white',
        fontSize: 18,
        fontFamily: 'MontserratBold', // Remplacez 'YourCustomFont' par le nom de la police que vous souhaitez utiliser
    },
    errorMessage: {
        color: 'red',
        marginBottom: 20,
    },
});

export default ChoixConnexionScreen;
