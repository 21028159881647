import {Dimensions, SafeAreaView, StyleSheet, TouchableOpacity, View, Text, Image, TextInput} from "react-native";
import {useEffect, useRef, useState} from "react";
import RNPickerSelect from 'react-native-picker-select';
import {useNavigation} from "@react-navigation/native";
import {v4 as uuidv4} from "uuid";

const BerealCaptureScreen = () => {
  const navigation = useNavigation();

  const [devices, setDevices] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState("");

  const videoRef = useRef(null);

  useEffect(() => {
    const getDevices = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({video: true});
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === 'videoinput'
        );
        setDevices([...videoDevices]);
      } catch (error) {
        console.error("Error accessing media devices.", error);
      }
    }

    void getDevices();

    const setCookieUser = async () => {
      const userId = localStorage.getItem('userId');
      const username = localStorage.getItem('username');

      if (!userId) {
        const newUserId = uuidv4();
        localStorage.setItem('userId', newUserId);
        setUserId(newUserId);
      } else {
        setUserId(userId);
      }

      if (username) setUsername(username);
    }

    void setCookieUser();
  }, []);

  useEffect(() => {
    setItems((prevState) => devices.map((device) => ({
        label: device.label || 'Default',
        value: device.deviceId || 'Default',
        key: device.deviceId || 'Default',
      }))
    );
    setSelectedDevice(devices[0]?.deviceId);
  }, [devices])

  useEffect(() => {
    if (selectedDevice) {
      startVideo(selectedDevice);
    }
  }, [selectedDevice]);

  useEffect(() => {
    if (imageData) return;
    if (selectedDevice) {
      startVideo(selectedDevice);
    }
  }, [imageData]);
  const startVideo = async (deviceId) => {
    try {
      videoRef.current.srcObject = await navigator.mediaDevices.getUserMedia({
        video: {deviceId: {exact: deviceId}},
      });
    } catch (error) {
      console.error('Error accessing the camera.', error);
    }
  };

  const captureImage = () => {
    const desiredRatio = 4 / 3; // Height / Width for portrait
    const video = videoRef.current;

    const canvas = document.createElement('canvas');

    // Calculate dimensions to achieve desired ratio while covering the video frame
    let sourceWidth = video.videoWidth;
    let sourceHeight = video.videoHeight;
    let sourceX = 0;
    let sourceY = 0;

    const videoRatio = video.videoHeight / video.videoWidth;

    if (videoRatio > desiredRatio) {
      // If video height is too much for the desired ratio
      sourceHeight = video.videoWidth * desiredRatio;
      sourceY = (video.videoHeight - sourceHeight) / 2;
    } else {
      // If video width is too much for the desired ratio
      sourceWidth = video.videoHeight / desiredRatio;
      sourceX = (video.videoWidth - sourceWidth) / 2;
    }

    // Set the canvas dimensions to be in the desired 4:3 ratio.
    // Here, you can set the dimensions to be any size, as long as the ratio is 4:3.
    // For instance, 400x300, 800x600, etc.
    canvas.width = 600; // Example width
    canvas.height = 800; // Example height

    const ctx = canvas.getContext('2d');

    // Draw the current frame from the video element onto the canvas
    ctx.drawImage(video, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, canvas.width, canvas.height);

    // Convert the canvas image to Base64 encoded string
    const imageData = canvas.toDataURL('image/png');
    setImageData(imageData);
  };

  const handleSubmit = () => {
    if (!imageData) return;
    const usernameCookie = localStorage.getItem('username');

    if (!usernameCookie && username) {
      localStorage.setItem('username', username);
    }

    fetch(' http://127.0.0.1:8000/api/upload_image', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({file: imageData, userId, username}),
    })
      .then((response) => {
        if (response.status === 201) {
          // Image uploaded successfully
          console.log('Image uploaded successfully.');
          navigation.navigate('CRéel');
        } else {
          // Handle error
          console.error('Image upload failed.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  return (
    <SafeAreaView style={styles.screenContainer}>
      {imageData
        ? (
          <View style={styles.videoContainer}>
            <View style={styles.videoScreen}>
              <Image source={{uri: imageData}} style={styles.imageElement}/>
            </View>
            <TouchableOpacity onPress={() => setImageData(null)} style={styles.retakeButton}>
              <Text style={styles.text}>Reprendre</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => handleSubmit()} style={styles.berealButton}>
              <Text style={styles.text}>Envoyer</Text>
            </TouchableOpacity>
          </View>
        )
        : (
          <View style={styles.videoContainer}>
            <View style={styles.videoScreen}>
              <video id="videoElement" style={styles.videoElement} ref={videoRef} autoPlay playsInline muted></video>
            </View>
            <RNPickerSelect
              onValueChange={(value) => setSelectedDevice(value)}
              value={selectedDevice}
              items={items}
              style={{inputWeb: styles.inputWeb}}
            />
            {!localStorage.getItem("username") && <TextInput
              placeholder="Username"
              placeholderTextColor={'#3e366e'}
              onChangeText={(text) => setUsername(text)}
              value={username}
              style={styles.inputWeb}
            />}
            <TouchableOpacity onPress={() => captureImage()} style={styles.berealButton}>
              <Text style={styles.text}>Prendre le CRéel</Text>
            </TouchableOpacity>
          </View>
        )}
    </SafeAreaView>
  );
}

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  text: {
    color: 'white',
    fontSize: 20,
    fontFamily: 'TripBold',
    alignItems: 'center',
  },

  screenContainer: {
    flex: 1,
    backgroundColor: '#1b1835',
  },

  videoContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  videoScreen: {
    width: windowWidth * 0.8,
    height: windowWidth * 0.8 * (4 / 3),
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 6,
  },

  videoElement: {
    height: '100%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },

  imageElement: {
    height: '100%',
    width: '100%',
  },

  berealButton: {
    backgroundColor: '#110e24',
    padding: 10,
    borderRadius: 10,
    margin: 50,
    alignItems: 'center',
  },

  retakeButton: {
    backgroundColor: '#110e24',
    padding: 10,
    borderRadius: 10,
    marginTop: 20,
    alignItems: 'center',
  },

  inputWeb: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    width: windowWidth * 0.8,
    marginTop: 20,
    marginHorizontal: 'auto',
    borderWidth: 1,
    borderColor: '#110e24',
    borderRadius: 6,
    color: 'white',
    fontFamily: 'TripBold',
    backgroundColor: '#110e24',
  },
});

export default BerealCaptureScreen;