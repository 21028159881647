import React, { useState } from 'react'; // Import useState
import { View, Text, StyleSheet, SafeAreaView, ScrollView, Image, TouchableOpacity, Modal } from 'react-native'; // Add Modal
import { useNavigation } from '@react-navigation/native';
import { WebView } from 'react-native-webview';
import { Dimensions } from 'react-native'; // Importez Dimensions pour obtenir la largeur de l'écran



const ProgrammeScreen = () => {

    const [videoId, setVideoId] = useState(null); // Add state to hold the video ID
    const navigation = useNavigation();


    const windowWidth = Dimensions.get('window').width; // Obtenez la largeur de l'écran



    const carouselItemsEtudiant = [
        { id: 1, title: 'SOIREES', image: require('./assets/img/programme/soiree.jpg'),videoId: 'QlvUf9jaO68'},
        { id: 2, title: 'JOURNEES', image: null},
        { id: 3, title: 'PROJETS', image: null},
        { id: 4, title: 'APP', image: null},
        { id: 5, title: 'THEME', image: null},
        { id: 6, title: 'BDE ?', image: null},
        { id: 7, title: 'POLES ?', image: null},

    ];

    const handleItemPress = (videoId) => {
        setVideoId(videoId); // Set the video ID when an item is pressed
    };

    const handleViewClick = () => {
        navigation.navigate('Evenements');
    };

    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
            <View style={styles.container}>
                <TouchableOpacity onPress={handleViewClick}>
                    <Image
                        source={require('./assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>

                <Text style={styles.title}>Qui sommes-nous ?</Text>
                <Text style={styles.subtitle}>Découvrez nous au travers de ces vidéos d'explications</Text>
                <ScrollView contentContainerStyle={styles.scrollViewContent} showsVerticalScrollIndicator={false}>
                    <View style={styles.carouselContainer}>
                        {carouselItemsEtudiant.map((item) => (
                            <TouchableOpacity key={item.id} style={styles.carouselItem} onPress={() => handleItemPress(item.videoId)}>
                                <Image source={item.image} resizeMode="cover" style={styles.carouselItemImage} />
                                <Text style={styles.carouselItemText}>{item.title}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                </ScrollView>

                {videoId && (
                    <Modal
                        transparent={true}
                        animationType="slide"
                        visible={!!videoId}
                        onRequestClose={() => setVideoId(null)}
                    >
                        <View style={styles.overlay}>
                            <View style={styles.videoContainer}>
                                <iframe width="397" height="706" src="https://www.youtube.com/embed/HYN-ETY4kmM?autoplay=1"
                                        title="Phuket street photographer #streetphotography" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen>

                                </iframe>
                                <TouchableOpacity style={styles.closeButton} onPress={() => setVideoId(null)}>
                                    <Text style={styles.closeButtonText}>X</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </Modal>
                )}
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({

    text: {
        fontSize: 24,
        color: 'white',
    },
    carouselItem: {
        width: 170,
        height: 250,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#110E24',
        borderRadius: 10,
        marginVertical: 10,
        marginHorizontal:5,
    },
    carouselItemImage: {
        width: '100%',
        height: '80%',
        borderRadius: 10,
        marginTop: -17,
    },
    carouselItemText: {
        color: 'white',
        fontSize: 18,
        fontFamily: 'MontserratBold', // Use the Montserrat Bold font
        marginTop:5,
    },
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    title: {
        fontSize: 28,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 0,
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'MontserratRegular',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
    },



    carouselItemColor: {
        width: '100%',
        height: '15%',
        backgroundColor: '#110E24',
        position: 'absolute',
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },

    emptyItem: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },

    scrollViewContent: {
        flexGrow: 1,
        justifyContent: 'center',
    },
    carouselContainer: {
        flexDirection: 'row', // Items will be laid out horizontally
        flexWrap: 'wrap', // Items will wrap to the next line when there's not enough space
        justifyContent: 'center', // Align items horizontally in the center
    },
    overlay: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    videoContainer: {
        backgroundColor: '#000',
        alignItems: 'center', // Centrez le WebView
        justifyContent: 'center', // Centrez le WebView
        flex: 1, // Faites en sorte que le conteneur prenne toute la hauteur disponible
    },
    closeButton: {
        position: 'absolute',
        top: 20,
        right: 20,
        backgroundColor: 'rgba(255,0,0,0.7)',
        borderRadius: 15,
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeButtonText: {
        color: 'white',
        fontSize: 24,
    },

    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 20,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    scrollViewContainer: {
        flexGrow: 1,
        marginBottom:-120,
    },
    centerScrollView: {
        justifyContent: 'center',
        alignItems: 'center',
    },

    rectangle: {
        backgroundColor: '#110E24',
        height: 40,
        borderRadius: 25,
        marginVertical: 10,
        marginHorizontal: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    elementText: {
        fontSize: 16,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginRight: 8,
        marginLeft: 8,
    },

    sectionTitle: {
        fontSize: 20,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        textTransform :"capitalize",
    },
    sportIconContainer: {
        marginTop: -10,
        alignSelf: 'center', // Align the sport icon vertically in the itemTextContainer
    },
    sportIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        marginTop: 8,
    },


    leftContainer: {
        flex: 1,
        marginLeft: -25, // Adjust the marginLeft to center the left container
        alignItems: 'center', // Add this line to center the content inside the left container
    },


    typeText: {
        fontSize: 13,
        fontFamily: 'MontserratRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', // Add this line to align the content vertically
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer: {
        alignItems: 'center',
    },

    itemContainer: {
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        alignItems: 'flex-start', // Use 'flex-start' to align content at the top
    },


    container2: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    itemContainer2: {
        flexDirection: 'row',
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
    },
    leftContainer2: {
        flex: 1,
        marginLeft: -25,
    },


    typeText2: {
        fontSize: 13,
        fontFamily: 'MontserratRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText2: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer2: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer2: {
        flexDirection: 'row',
        justifyContent: 'space-between', // Pour aligner l'icône à droite du rectangle
        alignItems: 'center',
    },
    itemInfoContainer2: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer2: {
        alignItems: 'center',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight: 10,
        marginTop: 10,
    },
    logoImg: {
        width: 80,
        height: 80,
        marginLeft: 25,
    },
    selectedRectangle: {
        backgroundColor: '#8ECAE6', // Light blue background for selected items
    },
    selectedRectangleTrue: {
        backgroundColor: '#098A2D', // Green background when item.value is true
    },
    selectedRectangleFalse: {
        backgroundColor: '#921616', // Red background when item.value is false
    },
});

export default ProgrammeScreen;
