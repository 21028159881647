import {
  ScrollView,
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  ImageBackground,
  Dimensions,
  Image,
  TouchableOpacity,
  Modal,
} from 'react-native';
import React, {useState, useEffect} from 'react';
import {useNavigation} from "@react-navigation/native";
import backgroundImage from './assets/bg/fond_app.png';  // Assurez-vous que le chemin est correct
import {Svg, Defs, LinearGradient, Stop, Rect, Text as SvgText} from 'react-native-svg';
import logoIcon from "./assets/LogoSansFond.png";

const VideoPlayer = ({url, onClose}) => {
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={!!url}
      onRequestClose={onClose}
    >
      <View style={styles.overlay}>
        <video
          controls
          src={url}
          style={styles.video}
        />
        <TouchableOpacity style={styles.closeButton} onPress={onClose}>
          <Text style={styles.closeButtonText}>X</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};





const ListeScreen2 = () => {
  const navigation = useNavigation();

  function shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // Tant qu'il reste des éléments à mélanger...
    while (currentIndex !== 0) {

      // Choisissez un élément restant...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // Et échangez-le avec l'élément actuel.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  const members = shuffle(require('./membres.json'))

  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);

  const showOverlay = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setOverlayVisible(true);
  };

  const hideOverlay = () => {
    setOverlayVisible(false);
  };


  const handleViewClick = (screen) => {
    navigation.navigate(screen);
  };

  // Remplacez 'chemin_vers_votre_image' par le chemin réel vers votre image dans le projet

  return (
    <View style={styles.screenContainer}>
      <ImageBackground source={backgroundImage} resizeMode="cover" style={styles.imageBackground}>
        <ScrollView style={styles.scrollView}
                    showsVerticalScrollIndicator={false}> {/* Ajoutez le composant ScrollView ici */}
          <View style={styles.container}>
            <View style={styles.header}>
              <TouchableOpacity onPress={() => handleViewClick("Liste")}
                                style={{flexDirection: 'row', alignItems: 'center', justifyContent: "center"}}>
                <Image
                  source={require('./assets/icons/header/fleche.png')}
                  style={[styles.backButton, styles.rotate180]}
                  resizeMode="contain"
                />
                <Text style={{
                  paddingHorizontal: 30,
                  fontSize: 28,
                  fontFamily: 'TripBold',
                  color: "white",
                  textAlign: 'center'
                }}>RETOUR</Text>

              </TouchableOpacity>
            </View>
            <Image source={logoIcon} style={[styles.logo]} resizeMode="contain"/>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 20,
              fontSize: 50,
              lineHeight: 48,
              fontFamily: 'TripUltra',
              color: "white",
              textAlign: 'center'
            }}>QUI SOMMES NOUS ?</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 20,
              fontSize: 22,
              lineHeight: 20,
              fontFamily: 'TripRegular',
              color: "white",
              textAlign: 'center'
            }}>Nous sommes 49 pilotes réunis pour vous faire kiffer et vous aider durant votre année au
              Pôle. {'\n'}{'\n'}Nous organisons journées, soirées, événements et projets.</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 50,
              fontSize: 50,
              lineHeight: 48,
              fontFamily: 'TripUltra',
              color: "white",
              textAlign: 'center'
            }}>NOS AMBITIONS</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 20,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              textAlign: 'center'
            }}>- Vous faire kiffer</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              textAlign: 'center'
            }}>- Vous aider au Pôle</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              textAlign: 'center'
            }}>- Vous intégrer au sein du Pôle</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              lineHeight: 21,
              fontFamily: 'TripRegular',
              color: "white",
              textAlign: 'center'
            }}>- Vous amenez dans des expériences mémorables</Text>
            {/*<Text style={{*/}
            {/*  paddingHorizontal: 30,*/}
            {/*  marginTop: 50,*/}
            {/*  fontSize: 50,*/}
            {/*  lineHeight: 48,*/}
            {/*  fontFamily: 'TripUltra',*/}
            {/*  color: "white",*/}
            {/*  textAlign: 'center'*/}
            {/*}}>VIDEOS</Text>*/}
            {/*<Text style={{*/}
            {/*  paddingHorizontal: 30,*/}
            {/*  marginTop: 5,*/}
            {/*  fontSize: 22,*/}
            {/*  lineHeight: 21,*/}
            {/*  fontFamily: 'TripRegular',*/}
            {/*  color: "white",*/}
            {/*  textAlign: 'center'*/}
            {/*}}>Découvrez-nous en vidéo !</Text>*/}

            {/*<ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.carouselContainer}>*/}
            {/*  <TouchableOpacity // Use TouchableOpacity instead of View for item 1*/}
            {/*    style={styles.carouselItem2}*/}
            {/*    onPress={() => showOverlay("https://res.cloudinary.com/dskakdlnn/video/upload/f_auto:video,q_auto/v1/videos/xtimuhpjb0a8zrx5j2tt")}*/}

            {/*  >*/}
            {/*    <ImageBackground*/}
            {/*      source={"https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_200/v1/videos/vignettes/oba2hds51gpzv5s9ajri"} // Assurez-vous que l'URL de l'image est correcte*/}
            {/*      style={styles.carouselItemImage}*/}
            {/*      imageStyle={styles.backgroundImage} // appliquer le style d'image ici*/}


            {/*    >*/}
            {/*      <Text style={{*/}
            {/*        fontSize: 40,*/}
            {/*        fontFamily: 'TripBold',*/}
            {/*        color: "white",*/}
            {/*        marginBottom: -10,*/}
            {/*        textAlign: 'center'*/}
            {/*      }}>NOTRE</Text>*/}

            {/*      <Text style={{*/}
            {/*        fontSize: 40,*/}
            {/*        fontFamily: 'TripBold',*/}
            {/*        color: "#F18B51",*/}
            {/*        textAlign: 'center',*/}
            {/*        textShadowColor: '#F18B51',*/}
            {/*        textShadowOffset: {width: -1, height: 1},*/}
            {/*        textShadowRadius: 5*/}
            {/*      }}>THEME</Text>*/}
            {/*    </ImageBackground>*/}
            {/*  </TouchableOpacity>*/}
            {/*  <TouchableOpacity // Use TouchableOpacity instead of View for item 1*/}
            {/*    style={styles.carouselItem2}*/}
            {/*  >*/}
            {/*    <ImageBackground*/}
            {/*      source={"https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_200/v1/videos/vignettes/lj42jjovvfqy9pgol2pn"} // Assurez-vous que l'URL de l'image est correcte*/}
            {/*      style={styles.carouselItemImage}*/}
            {/*      imageStyle={styles.backgroundImage} // appliquer le style d'image ici*/}


            {/*    >*/}
            {/*      <Text style={{*/}
            {/*        fontSize: 40,*/}
            {/*        fontFamily: 'TripBold',*/}
            {/*        color: "white",*/}
            {/*        marginBottom: -10,*/}
            {/*        textAlign: 'center'*/}
            {/*      }}>NOS</Text>*/}

            {/*      <Text style={{*/}
            {/*        fontSize: 40,*/}
            {/*        fontFamily: 'TripBold',*/}
            {/*        color: "#F18B51",*/}
            {/*        textAlign: 'center',*/}
            {/*        textShadowColor: '#F18B51',*/}
            {/*        textShadowOffset: {width: -1, height: 1},*/}
            {/*        textShadowRadius: 5*/}
            {/*      }}>PROJETS</Text>*/}
            {/*    </ImageBackground>*/}
            {/*  </TouchableOpacity>*/}
            {/*</ScrollView>*/}


            <Text style={{
              paddingHorizontal: 30,
              marginTop: 50,
              fontSize: 50,
              lineHeight: 48,
              fontFamily: 'TripUltra',
              color: "white",
              textAlign: 'center'
            }}>NOS MEMBRES</Text>

            <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.carouselContainer}>
              {members.map((item) => (
                <TouchableOpacity // Use TouchableOpacity instead of View for item 1
                  style={styles.carouselItem}
                >
                  <Image source={item.image} resizeMode="cover" style={styles.carouselItemImage2}/>
                  <Text style={styles.carouselItemText}>{item.prenom}</Text>
                  <Text style={styles.carouselItemText2}>{item.role}</Text>
                </TouchableOpacity>
              ))}


            </ScrollView>

            <Text style={{paddingHorizontal: 30, marginTop: 30, fontSize: 34, fontFamily: 'TripBold', color: "white"}}>NOS JOURNÉES</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              textAlign: "center",
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: 'white'
            }}>
              Carnaval<br/>
              Coachella<br/>
              Iles<br/>
              Safaride
            </Text>

            <Text style={{paddingHorizontal: 30, marginTop: 30, fontSize: 34, fontFamily: 'TripBold', color: "white"}}>NOS SOIRÉES</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              textAlign: "center",
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: 'white'
            }}>
              Célérity<br/>
              Valhalla<br/>
              Gala<br/>
              Amérique Latine <br/>
              Vinci OG's <br/>
              Woodstock <br/>
              Battle Asso
            </Text>


            <Text style={{
              paddingHorizontal: 30,
              marginTop: 50,
              fontSize: 50,
              lineHeight: 48,
              fontFamily: 'TripUltra',
              color: "white",
              textAlign: 'center'
            }}>PROJETS</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              fontSize: 34,
              fontFamily: 'TripBold',
              color: "white",
              marginLeft: -260
            }}>APP</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              lineHeight: 22
            }}>Application étudiante vous permettant d’accéder à des outils utiles pour la vie sur le Campus</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              fontSize: 34,
              fontFamily: 'TripBold',
              color: "white",
              marginLeft: 50
            }}>CINEMA PLEIN AIR</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              lineHeight: 22,
              textAlign: "right"
            }}>Festival d'été en plein air avec : projection d’un film, concert, shows et stands asso. De quoi passer
              une bonne soirée pour conclure cette année (JUIN)</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              fontSize: 34,
              fontFamily: 'TripBold',
              color: "white",
              marginLeft: -15
            }}>SOIREE LAN GAMING</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              lineHeight: 22,
              textAlign: "left"
            }}>Organisation d’une LAN pour les étudiants du pôle avec des tournois mais aussi du gaming chill entre
              amis. Le tout pour profiter d’une soirée gaming mémorable (MARS)</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              fontSize: 34,
              fontFamily: 'TripBold',
              color: "white",
              marginLeft: 50
            }}>PROJET GOODIES</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              lineHeight: 22,
              textAlign: "right"
            }}>Faciliter l’achat de goodies auprès de nos partenaires pour tout groupe d’étudiants. L’objectif : vous
              obtenir les meilleurs prix</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              fontSize: 34,
              fontFamily: 'TripBold',
              color: "white",
              marginLeft: -125
            }}>CLEAN WALK</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              lineHeight: 22,
              textAlign: "left"
            }}>Une Clean Walk à grande échelle pour agir pour l’environnement et se sensibiliser aux enjeux
              environnementaux (SEPTEMBRE)</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              fontSize: 34,
              fontFamily: 'TripBold',
              color: "white",
              marginRight: -45
            }}>MARAUDE GEANTE</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              lineHeight: 22,
              textAlign: "right"
            }}>Une maraude pour tous afin d’aider les personnes dans le besoin et d’agir pour la bonne cause (AVRIL)</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              fontSize: 34,
              fontFamily: 'TripBold',
              color: "white",
              marginLeft: -125
            }}>AW PULVIENS</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              lineHeight: 22,
              textAlign: "left"
            }}>Un afterwork de fin d’année pour réunir les 3 écoles autour d’un verre et d’animations. Pour clôturer
              l’année en beauté ! (MAI)</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              fontSize: 34,
              fontFamily: 'TripBold',
              color: "white",
              marginRight: -110
            }}>NEWLYPIADES</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              lineHeight: 22,
              textAlign: "right"
            }}>Un event remplis de défis pour les nouveaux intégrés de chaque asso afin de découvrir qui sera la
              meilleure asso. Un event basé sur l’intégration (OCTOBRE)</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              fontSize: 34,
              fontFamily: 'TripBold',
              color: "white",
              marginRight: 0
            }}>ESCAPE GAME GEANT</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 5,
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: "white",
              lineHeight: 22,
              textAlign: "left"
            }}>Un escape game organisé sur le Campus pour réunir les étudiants autour d'un challenge et trouver la meilleure équipe (AVRIL)</Text>
            <Text style={{paddingHorizontal: 30, marginTop: 30, fontSize: 34, fontFamily: 'TripBold', color: "white"}}>ET
              PLEINS D'AUTRES</Text>
            <Text style={{
              paddingHorizontal: 30,
              marginTop: 30,
              textAlign: "center",
              fontSize: 22,
              fontFamily: 'TripRegular',
              color: 'white'
            }}>
              Appart-Hunter<br/>
              RestoProxi<br/>
              Affichage au Pôle<br/>
            </Text>




            <Image
              source="https://res.cloudinary.com/dskakdlnn/image/upload/v1700341034/asso/by_CELEST_1_jeyv6u.png"
              style={{width: 169, height: 47, alignSelf: "center", marginVertical: 100}}
            />


            {/* Autres composants vont ici */}
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
  },
  imageBackground: {
    flex: 1,
    justifyContent: "center", // Centre le contenu sur l'axe des ordonnées
  },
  scrollView: {
    flex: 1, // Assurez-vous que ScrollView prend toute la hauteur
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 30,
  },
  backButton: {
    width: 30,
    height: 30,
    marginRight: 10,
    cursor: 'pointer',
  },
  rotate180: {
    transform: [{rotate: '180deg'}],
  },
  logo: {
    width: 350,
    height: 350,
    marginBottom: -30,
    resizeMode: 'contain',
  },
  carouselContainer: {
    width: '100%',
    overflowX: 'scroll',
    WebkitOverflowScrolling: 'touch',
    marginTop: 10,
  },
  carouselItemImage: {
    width: 200, // et ces deux lignes sont nécessaires pour redimensionner correctement
    height: "100%", // l'image en fonction du conteneur
    justifyContent: 'center', // pour centrer le texte à l'intérieur
    alignItems: 'center', // idem, pour le centrage horizontal

  },
  backgroundImage: {
    borderRadius: 25, // cela ajoutera des coins arrondis à votre image
    opacity: 0.3
    // Notez que si vous avez un borderRadius sur carouselItem, il devrait être de la même valeur ici
  },
  carouselItem2: {
    width: 200,
    height: 280,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#110E24',
    borderRadius: 50,
    margin: 10,
  },
  carouselItem: {
    width: 200,
    height: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#110E24',
    borderRadius: 10,
    margin: 10,
  },
  carouselItemImage2: {
    width: '100%',
    height: '75%',
    borderRadius: 10,
    marginTop: -17,
  },
  carouselItemText: {
    color: 'white',
    fontSize: 24,
    fontFamily: 'TripBold', // Use the Montserrat Bold font
    marginTop: 5,
  },
  carouselItemText2: {
    color: 'white',
    fontSize: 20,
    fontFamily: 'TripRegular', // Use the Montserrat Bold font
    marginTop: -5,
  },
  overlayContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  closeButton: {
    position: 'absolute',
    top: 30,
    right: 15,
    backgroundColor: 'red',
    borderRadius: 15,
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButtonText: {
    color: 'white',
    fontSize: 20,
  },
  videoPlayer: {
    width: '90%',
    height: '50%',
  },
  videoContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
  },
  video: {
    width: '100%',
    height: '100%',
  },


});

export default ListeScreen2;
