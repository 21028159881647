// App.js
import React, { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Footer from './Footer';
import SplashScreen from './SplashScreen';
import EtudiantScreen from './EtudiantScreen';
import PasseportScreen from './PasseportScreen';
import EvenementsScreen from './EvenementsScreen';
import RecapScreen from './RecapScreen';
import ProfilScreen from './ProfilScreen';
import EcheancesScreen from './etudiant/EcheancesScreen';
import DisponibilitesScreen from "./etudiant/DisponibilitesScreen";
import ResultatScreen from "./etudiant/ResultatScreen";
import RecrutementScreen from "./etudiant/RecrutementScreen";
import ModifierProfilScreen from "./profil/ModifierProfilScreen";
import PromosScreen from "./profil/PromosScreen";
import BilletScreen from "./profil/BilletScreen";
import LoginScreen from "./LoginScreen";
import ProfilInviteScreen from "./ProfilInviteScreen";
import RegisterScreen from "./RegisterPage";
import ChoixConnexionScreen from "./ChoixConnexionScreen";
import TeamScreen from "./Equipe";
import ProgrammeScreen from "./ProgrammeScreen";
import ListeScreen from "./ListeScreen";
import FAQScreen from "./etudiant/FAQScreen";
import RestaurationScreen from "./etudiant/RestaurationScreen";
import FAQBDEScreen from "./faq/FAQBDEScreen";
import FAQAssoScreen from "./faq/FAQAssoScreen";
import FAQPoleScreen from "./faq/FAQPoleScreen";
import ListeScreen2 from "./ListeScreen2";
import BerealScreen from "./BerealScreen";
import BerealCaptureScreen from "./BerealCaptureScreen";
import Travaux from "./Travaux";



const Tab = createBottomTabNavigator();

const App = () => {
    const [showSplash, setShowSplash] = useState(true);

    useEffect(() => {
        // Function to hide the splash screen after 2 seconds
        const timer = setTimeout(() => {
            setShowSplash(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <NavigationContainer>
            {showSplash ? (
                <SplashScreen />
            ) : (
                <Tab.Navigator
                    tabBar={(props) => <Footer {...props} currentPage="Evenements" />}
                    screenOptions={{ headerShown: false, cardStyle: { backgroundColor: '#1B1835' } }}
                >
                    <Tab.Screen name="Travaux" component={Travaux} />
                    {/*<Tab.Screen name="Liste" component={ListeScreen} />
                    <Tab.Screen name="Liste2" component={ListeScreen2} />
                    <Tab.Screen name="ChoixConnexion" component={ChoixConnexionScreen} />
                    <Tab.Screen name="Login" component={LoginScreen} />
                    <Tab.Screen name="Evenements" component={EvenementsScreen} />
                    <Tab.Screen name="Etudiant" component={EtudiantScreen} />
                    <Tab.Screen name="Passeport" component={PasseportScreen} />
                    <Tab.Screen name="Recap" component={RecapScreen} />
                    <Tab.Screen name="CRéel" component={BerealScreen} />
                     Add the EcheancesScreen to the navigator
                    <Tab.Screen name="Echeances" component={EcheancesScreen} />
                    <Tab.Screen name="Disponibilites" component={DisponibilitesScreen} />
                    <Tab.Screen name="Recrutement" component={RecrutementScreen} />
                    <Tab.Screen name="Resultat" component={ResultatScreen} />
                    <Tab.Screen name="ModifierProfil" component={ModifierProfilScreen} />
                    <Tab.Screen name="Promos" component={PromosScreen} />
                    <Tab.Screen name="Billets" component={BilletScreen} />
                    <Tab.Screen name="ProfilInvite" component={ProfilInviteScreen} />
                    <Tab.Screen name="Register" component={RegisterScreen} />
                    <Tab.Screen name="Equipe" component={TeamScreen} />
                    <Tab.Screen name="Programme" component={ProgrammeScreen} />
                    <Tab.Screen name="FAQ" component={FAQScreen} />

                    <Tab.Screen name="Restauration" component={RestaurationScreen} />
                    <Tab.Screen name="FAQBDE" component={FAQBDEScreen} />
                    <Tab.Screen name="FAQAsso" component={FAQAssoScreen} />
                    <Tab.Screen name="FAQPole" component={FAQPoleScreen} />

                    <Tab.Screen name="BerealCapture" component={BerealCaptureScreen} />*/}



                </Tab.Navigator>
            )}
        </NavigationContainer>
    );
};

export default App;
