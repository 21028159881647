import React, { useRef, useState, useEffect } from 'react';
import {
    View,
    Text,
    StyleSheet,
    SafeAreaView,
    Image,
    TouchableOpacity,
    ScrollView,
    Dimensions
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

const DisponibilitesScreen = () => {
    const navigation = useNavigation();
    const containerRef = useRef(null);
    const [shouldCenterScrollView, setShouldCenterScrollView] = useState(false);

    const handleViewClick = () => {
        navigation.navigate('Etudiant');
    };

    const checkShouldCenterScrollView = () => {
        if (containerRef.current && containerRef.current.scrollWidth <= Dimensions.get('window').width) {
            setShouldCenterScrollView(true);
        } else {
            setShouldCenterScrollView(false);
        }
    };

    const items = [
        {
            label: 'Scolarité',
            value: true,
            categorie:"service"
        },
        {
            label: 'International',
            value: false,
            categorie:"service"
        },
        {
            label: 'Vie Asso',
            value: true,
            categorie:"service"
        },
        {
            label: 'Informatique',
            value: false,
            categorie:"service"
        },
        {
            label: 'Services Généraux',
            value: false,
            categorie:"service"
        },
        {
            label: 'Langues',
            value: true,
            categorie:"service"
        },



        {
            label: 'Accès Pont',
            value: false,
            categorie:"IIM"
        },
        {
            label: 'Caféteriat',
            value: true,
            categorie:"IIM"
        },
        {
            label: 'Studio',
            value: true,
            categorie:"IIM"
        },
        {
            label: 'Mezzanine',
            value: false,
            categorie:"IIM"
        },



        {
            label: 'Learning Center',
            value: false,
            categorie:"salles"
        },
        {
            label: 'Foyer',
            value: true,
            categorie:"salles"
        },



        {
            label: 'Pizza',
            value: false,
            categorie:"cantine"
        },
        {
            label: 'Picard',
            value: true,
            categorie:"cantine"
        },
        {
            label: 'Sushi',
            value: false,
            categorie:"cantine"
        },
        {
            label: 'Salle cantine',
            value: true,
            categorie:"cantine"
        },
        {
            label: 'Salade',
            value: false,
            categorie:"cantine"
        },



        {
            label: "Jil's",
            value: false,
            categorie:"restauration"
        },
        {
            label: 'Jungle Gorill',
            value: true,
            categorie:"restauration"
        },
        {
            label: "Soleil d'Or",
            value: false,
            categorie:"restauration"
        },
        {
            label: 'Monoprix',
            value: true,
            categorie:"restauration"
        },
        {
            label: 'Mongoo',
            value: false,
            categorie:"restauration"
        },

    ];

    const renderPostes = () => {
        // Group items by categories
        const groupedItems = {};
        items.forEach((item) => {
            if (!groupedItems[item.categorie]) {
                groupedItems[item.categorie] = [];
            }
            groupedItems[item.categorie].push(item);
        });

        return (
            <View>
                <View contentContainerStyle={[styles.scrollViewContent, shouldCenterScrollView && styles.centerScrollView]}
                      onContentSizeChange={checkShouldCenterScrollView}
                >
                <ScrollView

                    horizontal={true}
                    showsHorizontalScrollIndicator={false}

                >
                    <Text style={styles.subtitle}>DEMO</Text>
                    <View>
                        {Object.keys(groupedItems).map((categorie, index) => (
                            <View key={index}>
                                <Text style={styles.sectionTitle}>{categorie}</Text>
                                <View style={styles.container} ref={containerRef}>
                                    {groupedItems[categorie].map((poste, index) => (
                                        <TouchableOpacity
                                            key={index}
                                            style={[
                                                styles.rectangle,
                                                poste.value ? styles.selectedRectangleTrue : styles.selectedRectangleFalse,
                                            ]}
                                        >
                                            <Text style={styles.elementText}>{poste.label}</Text>
                                        </TouchableOpacity>
                                    ))}
                                </View>
                            </View>
                        ))}
                    </View>
                </ScrollView>
                </View>
            </View>
        );
    };

    useEffect(() => {
        checkShouldCenterScrollView();
    }, []);

    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
            {/* Header */}
            <View style={styles.header}>
                <TouchableOpacity onPress={handleViewClick}>
                    <Image
                        source={require('../assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
                <Text style={styles.title}>Disponibilités</Text>
            </View>

            {/* Content */}
            <View style={styles.container}>
                {renderPostes()}
            </View>
        </SafeAreaView>
    );
};



const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 20,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    scrollViewContainer: {
        flexGrow: 1,
        marginBottom:-120,
    },
    centerScrollView: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    scrollViewContent: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    rectangle: {
        backgroundColor: '#110E24',
        height: 40,
        borderRadius: 25,
        marginVertical: 10,
        marginHorizontal: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    elementText: {
        fontSize: 16,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginRight: 8,
        marginLeft: 8,
    },
    title: {
        fontSize: 28,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        flex: 1,
    },
    sectionTitle: {
        fontSize: 20,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        textTransform :"capitalize",
    },
    sportIconContainer: {
        marginTop: -10,
        alignSelf: 'center', // Align the sport icon vertically in the itemTextContainer
    },
    sportIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        marginTop: 8,
    },


    leftContainer: {
        flex: 1,
        marginLeft: -25, // Adjust the marginLeft to center the left container
        alignItems: 'center', // Add this line to center the content inside the left container
    },


    typeText: {
        fontSize: 13,
        fontFamily: 'MontserratRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', // Add this line to align the content vertically
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer: {
        alignItems: 'center',
    },

    subtitle: {
        fontSize: 18,
        fontFamily: 'MontserratRegular',
        color: 'white',
        textAlign: 'center',
    },
    itemContainer: {
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        alignItems: 'flex-start', // Use 'flex-start' to align content at the top
    },


    container2: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    itemContainer2: {
        flexDirection: 'row',
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
    },
    leftContainer2: {
        flex: 1,
        marginLeft: -25,
    },


    typeText2: {
        fontSize: 13,
        fontFamily: 'MontserratRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText2: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer2: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer2: {
        flexDirection: 'row',
        justifyContent: 'space-between', // Pour aligner l'icône à droite du rectangle
        alignItems: 'center',
    },
    itemInfoContainer2: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer2: {
        alignItems: 'center',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight: 10,
        marginTop: 10,
    },
    logoImg: {
        width: 80,
        height: 80,
        marginLeft: 25,
    },
    selectedRectangle: {
        backgroundColor: '#8ECAE6', // Light blue background for selected items
    },
    selectedRectangleTrue: {
        backgroundColor: '#098A2D', // Green background when item.value is true
    },
    selectedRectangleFalse: {
        backgroundColor: '#921616', // Red background when item.value is false
    },
});

export default DisponibilitesScreen;
