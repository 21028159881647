import React, {useRef, useState, useEffect, useCallback} from 'react';
import {
    View,
    Text,
    StyleSheet,
    Image,
    TouchableOpacity,
    ScrollView,
    Dimensions,
    Linking,
    SafeAreaView
} from 'react-native';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import Cookies from "js-cookie";
import { Url} from '../global'

const RestaurationScreen = () => {
    const navigation = useNavigation();
    const containerRef = useRef(null);
    const [selectedElements, setSelectedElements] = useState([]);
    const [shouldCenterScrollView, setShouldCenterScrollView] = useState(false);

    const [selectedPostes, setSelectedPostes] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

/// Sépération prix (en moyenne) : € (0-8 euros) / €€ (8-12 euros) / €€€ (+ de 12 euros)

    const items = [
        {
            nom:"Distributeurs Pôle",
            type:"A emporter",
            lien:"",
            logo:"https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_200/v1/restauration/giqxca2t0hqkbef3t5nb",
            description:"Distributeurs en RH/RB/Arche - €"
        },

        {
            nom:"Monoprix",
            type:"A emporter",
            lien:"https://maps.app.goo.gl/ibb2zQHcH4tAKtRq7",
            logo:"https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_200/v1/restauration/l8i7zyh6raew5helhico",
            description:"Supermarché - €"
        },
        {
            nom:"Jil's",
            type:"A emporter / Sur place",
            lien:"https://maps.app.goo.gl/mdb7kV2ygZkKtbdy8",
            logo:"https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_200/v1/restauration/bsrsraowr07rutla4z7a",
            description:"Restaurant - €€"
        }
    ]



    const handleViewClick = () => {
        navigation.navigate('Etudiant');
    };





    const checkShouldCenterScrollView = () => {
        if (containerRef.current && containerRef.current.scrollWidth <= Dimensions.get('window').width) {
            setShouldCenterScrollView(true);
        } else {
            setShouldCenterScrollView(false);
        }
    };

    const renderArrowIcon = (link) => {
        if (link) {
            return (
                <Image
                    source={require('../assets/icons/header/fleche.png')}
                    style={styles.arrowIcon}
                    resizeMode="contain"
                />
            );
        }
        return null;
    };

    useEffect(() => {
        checkShouldCenterScrollView();
    }, []);



    const handleItemClick = (link) => {
        if (link) {
            // Vérifie si le lien commence par "http://" ou "https://", sinon ajoute "http://"
            if (!link.startsWith("http://") && !link.startsWith("https://")) {
                link = "http://" + link;
            }

            Linking.openURL(link).catch((err) => {
                console.error('Failed to open URL:', link, err);
            });
        }
    };



    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
        <ScrollView>
        <View >
            {/* Header */}
            <View style={styles.header}>
                <TouchableOpacity onPress={handleViewClick}>
                    <Image
                        source={require('../assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
                <Text style={styles.title}>Restauration</Text>
            </View>

            {/* Content */}

            <View style={styles.contentContainer}>

                    {/* Offres */}
                    <View style={styles.container2}>
                        <Text style={styles.subtitle}>Offres</Text>
                        {items.map((item, index) => (
                            <TouchableOpacity
                                key={index}
                                onPress={() => handleItemClick(item.lien)}
                                style={[styles.itemContainer2, { backgroundColor: '#110E24' }]}
                            >
                                <View style={styles.leftContainer2}>
                                    <Image
                                        source={item.logo}
                                        style={styles.logoImg}
                                        resizeMode="contain"
                                    />
                                </View>
                                <View style={styles.rightContainer2}>
                                    <View style={styles.itemTextContainer2}>
                                        <View style={styles.itemInfoContainer2}>
                                            <View style={styles.titleContainer2}>
                                                <Text style={styles.typeText2}>{item.type}</Text>
                                            </View>
                                            <Text style={styles.nameText2}>{item.nom}</Text>
                                            <Text style={styles.typeText2}>{item.description}</Text>
                                        </View>
                                        {renderArrowIcon(item.lien)}
                                    </View>
                                </View>
                            </TouchableOpacity>
                        ))}
                    </View>

            </View>

        </View>
        </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    scrollViewContainer: {
        flexGrow: 1,
        marginBottom:-120,
    },
    centerScrollView: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    scrollViewContent: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    rectangle: {
        backgroundColor: '#110E24',
        height: 40,
        borderRadius: 25,
        marginVertical: 10,
        marginHorizontal: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    elementText: {
        fontSize: 20,
        fontFamily: 'TripBold',
        color: 'white',
        marginRight: 8,
        marginLeft: 8,
    },
    title: {
        fontSize: 32,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        flex: 1,
    },
    sectionTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
    },
    sportIconContainer: {
        marginTop: -10,
        alignSelf: 'center', // Align the sport icon vertically in the itemTextContainer
    },
    sportIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        marginTop: 8,
    },


    leftContainer: {
        flex: 1,
        marginLeft: -25, // Adjust the marginLeft to center the left container
        alignItems: 'center', // Add this line to center the content inside the left container
    },


    typeText: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', // Add this line to align the content vertically
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer: {
        alignItems: 'center',
    },

    subtitle: {
        fontSize: 28,
        marginBottom: 10,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
    },
    itemContainer: {
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        alignItems: 'flex-start', // Use 'flex-start' to align content at the top
    },


    container2: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    itemContainer2: {
        flexDirection: 'row',
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
    },
    leftContainer2: {
        flex: 1,
        marginLeft: -25,
    },


    typeText2: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText2: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer2: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer2: {
        flexDirection: 'row',
        justifyContent: 'space-between', // Pour aligner l'icône à droite du rectangle
        alignItems: 'center',
    },
    itemInfoContainer2: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer2: {
        alignItems: 'center',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight: 10,
        marginTop: 10,
    },
    logoImg: {
        width: 80,
        height: 80,
        marginLeft: 25,
    },
    selectedRectangle: {
        backgroundColor: '#8ECAE6', // Light blue background for selected items
    },


});

export default RestaurationScreen;
