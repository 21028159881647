import React, {useState, useCallback, useEffect} from 'react'; // Ajoutez useCallback
import { useCookies } from 'react-cookie';
import {View, Text, StyleSheet, SafeAreaView, ScrollView, Image, TouchableOpacity, Dimensions} from 'react-native';
import { useNavigation,useFocusEffect } from '@react-navigation/native';
import QRCode from 'react-native-qrcode-svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Cookies from "js-cookie";
import { Url} from './global'


    const ProfilScreen = () => {
        const navigation = useNavigation();
        const [cookies, setCookie, removeCookie] = useCookies(['accessToken']); // Ajoutez removeCookie
        const [isViewVisible, setIsViewVisible] = useState(true);
        const [dependencyKey, setDependencyKey] = useState(0);


        const [user, setUser] = useState({
            "nom": "",
            "prenom": "",
            "username": "",
            "ecole": "",
            "annee": "",
            "equipe": "",
            "numero": null,
        });


        const items = [
            {nom:"Equipes",link:"youtube.fr",icon:"equipe.png",screen:"Equipe"},
            {nom:"Billets",link:"youtube.fr",icon:"billets.png",screen:"Billets"},
            {nom:"Promos",link:"youtube.fr",icon:"promos.png",screen: "Promos"},
            {nom:"Deconnexion",link:"youtube.fr",icon:"logout.png",screen: "Login"},

        ]


        useFocusEffect(
            useCallback(() => {

                if (!Cookies.get('accessToken')) {
                    setTimeout(() => navigation.navigate("ProfilInvite"), 50);
                }
                setIsViewVisible(true)


                const token = cookies.accessToken;

                setUser({
                    "nom": "",
                    "prenom": "",
                    "username": "",
                    "ecole": "",
                    "annee": "",
                    "equipe": "",
                    "numero": null,
                });


                if (token) {
                    fetch(Url+'api/whoami', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    })
                        .then((response) => {
                            if (response.status !== 200) {
                                if(response.status == 401){
                                    removeCookie('accessToken');
                                    navigation.navigate('Login');
                                    return;
                                }
                                else{
                                    navigation.navigate('Evenements');
                                    return;
                                }


                            }
                            return response.json();
                        })
                        .then((data) => data && setUser(data))
                        .catch((error) => console.error('There was an error fetching the data!', error));
                }
            }, [cookies.accessToken, navigation]) // Dépendances
        );



        const handleViewClick = (screen) => {
            if (screen === 'Login') {
                // Supprimez le cookie accessToken lors de la déconnexion
                removeCookie('accessToken');
            }
            navigation.navigate(screen);
        };


        return (

            <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
                <ScrollView>
                    <View style={styles.container}>
                        <View>

                            <TouchableOpacity style={styles.qrProfil} onPress={() => setIsViewVisible(true)}>

                                {user.numero && (
                                    <QRCode
                                        value={user.numero.toString()}
                                        size={200}
                                        color="black"
                                        backgroundColor="white"
                                    />
                                )}
                            </TouchableOpacity>
                            {isViewVisible && (
                                <TouchableOpacity onPress={() => setIsViewVisible(false)}>
                                    <View>
                                        <Image
                                            source={require('./assets/img/profil/passeport1.png')}
                                            style={styles.passeportProfil}
                                            resizeMode="contain"
                                        />
                                        <View>
                                            <Text style={StyleSheet.compose(styles.passeportText, { top: 84, left: -44 })}>{user.prenom}</Text>
                                            <Text style={StyleSheet.compose(styles.passeportText, { top: 118, left: -44 })}>{user.nom}</Text>
                                            <Text style={StyleSheet.compose(styles.passeportText, { top: 152, left: -44 })}>{user.username}</Text>
                                            <Text style={StyleSheet.compose(styles.passeportTextRight, { top: 84, right: -150 })}>{user.numero}</Text>
                                            <Text style={StyleSheet.compose(styles.passeportTextRight, { top: 118, right: -150 })}>{user.equipe}</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )}



                            {/* Ajoutez un autre composant ici pour gérer le clic à l'endroit où la vue était, pour la faire réapparaître. */}
                        </View>

                        <View style={styles.menuContainer}>
                            {items.map((item, index) => (
                                <TouchableOpacity
                                    key={index}
                                    style={[styles.itemContainer, { backgroundColor: '#110E24' }]}
                                    onPress={() => handleViewClick(item.screen)}
                                >
                                    <View style={styles.rightContainer}>
                                        <View style={styles.itemTextContainer}>
                                            <Image
                                                source={require('./assets/icons/profil/'+item.icon)}
                                                style={styles.arrowIcon}
                                                resizeMode="contain"
                                            />
                                            <View style={styles.itemInfoContainer}>

                                                <Text style={styles.nameText}>{item.nom}</Text>
                                            </View>
                                            <Image
                                                source={require('./assets/icons/header/fleche.png')}
                                                style={styles.arrowIcon}
                                                resizeMode="contain"
                                            />

                                        </View>
                                    </View>
                                </TouchableOpacity>
                            ))}

                        </View>
                    </View>

            </ScrollView>
            </SafeAreaView>

        );
    };

    const styles = StyleSheet.create({

        text: {
            fontSize: 24,
            color: 'white',
        },
        passeportProfil: {
            width: 500,
            height: 500,
            alignSelf: "center",
            position : "absolute",
            top : -225,
        },
        qrProfil: {
            alignSelf: "center",
            position : "absolute",
            top:35,
        },
        screenContainer: {
            flex: 1,
            backgroundColor: '#1B1835',
        },
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#1B1835',
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 10,
            paddingHorizontal: 20,
        },
        backButton: {
            width: 30,
            height: 30,
            marginRight: 10,
            cursor: 'pointer',
        },
        rotate180: {
            transform: [{ rotate: '180deg' }],
        },
        headerTitle: {
            fontSize: 20,
            fontFamily: 'MontserratBold',
            color: 'white',
            textAlign: 'center',
            marginLeft: 20,
        },
        itemContainer: {
            flexDirection: 'row',
            width: Dimensions.get('window').width < 500 ? '100%' : 500,
            borderRadius: 10,
            padding: 10,
            marginVertical: 5,
        },
        leftContainer: {
            flex: 1,
            marginLeft:-25,
        },
        dateContainer: {
            flexDirection: 'column', // Ajustement pour afficher les éléments l'un sous l'autre
            alignItems: 'center', // Aligner les éléments au centre horizontalement
            marginBottom: 5,
            marginLeft:-10,
        },
        dayText: {
            fontSize: 24,
            fontFamily: 'MontserratBold',
            color: 'white',
        },
        monthText: {
            fontSize: 24,
            fontFamily: 'MontserratBold',
            color: 'white',
            textTransform: 'uppercase', // Mettre en majuscule pour afficher les mois en majuscules
        },

        typeText: {
            fontSize: 13,
            fontFamily: 'MontserratRegular',
            color: 'white',
            marginBottom: 5,
        },

        nameText: {
            fontSize: 24,
            fontFamily: 'MontserratBold',
            color: 'white',
        },
        rightContainer: {
            flex: 2,
        },
        itemTextContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between', // Pour aligner l'icône à droite du rectangle
            alignItems: 'center',
        },
        itemInfoContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        titleContainer: {
            alignItems: 'center',
        },
        arrowIcon: {
            width: 25,
            height: 25,
            marginRight:10,
            marginLeft:15,
        },
        title: {
            fontSize: 28,
            fontFamily: 'MontserratBold',
            color: 'white',
            textAlign: 'center',
            marginBottom: 0,
        },
        subtitle: {
            fontSize: 18,
            fontFamily: 'MontserratRegular',
            color: 'white',
            textAlign: 'center',
            marginBottom: 20,


        },
        menuContainer:{
            position:"absolute",
            top:300,
        },

        passeportText:{
            fontFamily:'MontserratRegular',
            fontSize:15,
            color:'black',
            position:'absolute',



        },
        passeportTextRight:{
            fontFamily:'MontserratRegular',
            fontSize:15,
            color:'black',
            position:'absolute',
            textAlign:'right'


        }


    });

    export default ProfilScreen;
