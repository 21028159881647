import React from 'react';
import { ImageBackground,View, Text, StyleSheet, SafeAreaView, ScrollView, Image, TouchableOpacity } from 'react-native'; // Add TouchableOpacity
import { useNavigation } from '@react-navigation/native';

const EtudiantScreen = () => {
    const navigation = useNavigation();

    const carouselItemsEtudiant = [
        /*{ id: 1, title: 'Disponibilités', image: "https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_400/v1/etudiant/pre15tie3zmtnjbiayym", screen: 'Disponibilites' },*/
        { id: 2, title: 'Echéances', image: "https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_400/v1/etudiant/ohtuvjtpjxytbzjlbenx", screen: 'Echeances' },
        { id: 3, title: 'FAQ', image: "https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_400/v1/etudiant/osmfvibn6kudkhumkjys", screen: 'FAQ' },
        { id: 4, title: 'Restauration', image: "https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_500/v1/etudiant/r88jzs9tdq5qeop1ttbb", screen: 'Restauration' },
        { id: 5, title: 'A VENIR', image: null, screen: null },
    ];

    const carouselItemsAssociations = [
        { id: 6, title: 'Recrutement', image: "https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_400/v1/etudiant/hyybhbopfj4nzkkyxwxr" , screen: 'Recrutement' },
        { id: 7, title: 'Résultats', image: "https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_400/v1/etudiant/puul8oc9ayfvybhcwjgh", screen: 'Resultat'  },
        { id: 8, title: 'A VENIR', image: null, screen: null  },
        { id: 9, title: 'A VENIR', image: null, screen: null  },
    ];


    const handleViewClick = (screen) => {
        console.log(screen);
        navigation.navigate(screen);
    };


    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
            <View style={styles.container}>
                <Text style={styles.title}>Espace Etudiant</Text>
                <Text style={styles.subtitle}>Outils pour la vie du pôle</Text>
                <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.carouselContainer}>
                    {carouselItemsEtudiant.map((item) => (
                        <TouchableOpacity // Use TouchableOpacity instead of View for item 1
                            key={item.id}
                            style={styles.carouselItem}
                            onPress={() => handleViewClick(item.screen)}
                        >
                            <Image source={item.image} resizeMode="cover" style={styles.carouselItemImage} />
                            <Text style={styles.carouselItemText}>{item.title}</Text>
                        </TouchableOpacity>
                    ))}
                </ScrollView>
                <Text style={styles.title}>Espace Associations</Text>
                <Text style={styles.subtitle}>Outils pour la vie associative et sportive</Text>
                <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.carouselContainer}>
                    {carouselItemsAssociations.map((item) => (
                        <TouchableOpacity // Use TouchableOpacity instead of View for item 1
                            key={item.id}
                            style={styles.carouselItem}
                            onPress={() => handleViewClick(item.screen)}
                        >
                            <Image source={item.image} resizeMode="cover" style={styles.carouselItemImage} />
                            <Text style={styles.carouselItemText}>{item.title}</Text>
                        </TouchableOpacity>
                    ))}
                </ScrollView>
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    // ... (the rest of the styles remain unchanged)
    carouselItem: {
        width: 200,
        height: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#110E24',
        borderRadius: 10,
        margin: 10,
    },
    carouselItemImage: {
        width: '100%',
        height: '80%',
        borderRadius: 10,
        marginTop: -17,
    },
    carouselItemText: {
        color: 'white',
        fontSize: 24,
        fontFamily: 'TripBold', // Use the Montserrat Bold font
        marginTop:5,
    },
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
        marginTop:20,
    },
    title: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 0,
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
    },
    carouselContainer: {
        width: '100%',
        overflowX: 'scroll',
        WebkitOverflowScrolling: 'touch',
    },


    carouselItemColor: {
        width: '100%',
        height: '15%',
        backgroundColor: '#110E24',
        position: 'absolute',
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },

    emptyItem: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
});

export default EtudiantScreen;
