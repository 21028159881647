import {
    ScrollView,
    View,
    Text,
    StyleSheet,
    SafeAreaView,
    ImageBackground,
    Dimensions,
    Image,
    TouchableOpacity, Linking
} from 'react-native';
import React, { useState, useEffect } from 'react';
import { useNavigation } from "@react-navigation/native";
import backgroundImage from './assets/bg/fond_app.png';  // Assurez-vous que le chemin est correct
import { Svg, Defs, LinearGradient, Stop, Rect, Text as SvgText } from 'react-native-svg';
import logoIcon from "./assets/LogoSansFond.png";

const VideoPlayer = ({ videoUrl }) => (
    <View style={styles.container}>
        <video
            style={styles.video}
            src={videoUrl}
            controls
            autoPlay
            muted
            loop
            // Ajoutez d'autres attributs HTML vidéo ici selon vos besoins
        />
    </View>
);


const ListeScreen = () => {
    const navigation = useNavigation();

    const targetDate = new Date('November 25, 2023 11:00:00 GMT+0100');

    const calculateTimeLeft = () => {
        const difference = targetDate - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        // Nettoyage du timer si le composant est démonté ou si la date cible est atteinte
        return () => clearTimeout(timer);
    });

    // Rendu du compte à rebours dans le format JJ:HH:MM:SS
    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval, index) => {
        if (!timeLeft[interval]) {
            return;
        }

        // Ajout d'un deux-points après chaque intervalle, sauf le dernier
        const separator = index < Object.keys(timeLeft).length - 1 ? ':' : '';

        timerComponents.push(
            <Text style={{fontSize: 60, fontFamily: 'TripRegular', color: "white", textAlign: 'center'}}>
                {timeLeft[interval]}{separator}
            </Text>
        );
    });

    const handleItemClick = (link) => {
        if (link) {
            // Vérifie si le lien commence par "http://" ou "https://", sinon ajoute "http://"
            if (!link.startsWith("http://") && !link.startsWith("https://")) {
                link = "http://" + link;
            }

            Linking.openURL(link).catch((err) => {
                console.error('Failed to open URL:', link, err);
            });
        }
    };


    const handleViewClick = (screen) => {
        navigation.navigate(screen);
    };
    const width = '80%'; // La barre s'étendra sur toute la largeur du conteneur
    const height = 6; // Épaisseur de la barre

    // Remplacez 'chemin_vers_votre_image' par le chemin réel vers votre image dans le projet

    return (
        <View style={styles.screenContainer}>
            <ImageBackground source={backgroundImage} resizeMode="cover" style={styles.imageBackground}>
                <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}> {/* Ajoutez le composant ScrollView ici */}
                    <View style={styles.container}>
                        <Svg height="60" width="300" style={{marginTop:70,marginBottom:-30}}>
                            <Defs>
                                <LinearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
                                    <Stop offset="0" stopColor="#8DC9E6" stopOpacity="1" />
                                    <Stop offset="1" stopColor="#F18B51" stopOpacity="1" />
                                </LinearGradient>
                            </Defs>
                            <SvgText
                                fill="url(#gradient)"
                                fontSize="30"
                                fontWeight="bold"
                                x="50%"
                                y="50%"
                                textAnchor="middle"
                                alignmentBaseline="central"
                                style={styles.bienvenue}
                            >
                                BIENVENUE PILOTE
                            </SvgText>
                        </Svg>
                        <Image source={logoIcon} style={[styles.logo]} resizeMode="contain" />
                        <Text style={{fontSize: 32, fontFamily: 'TripRegular',color:"white", textAlign: 'center'}}>LISTE BDE</Text>
                        <View style={{flexDirection: 'row',marginTop:-40,marginBottom:40}}>
                            <Text style={[styles.bienvenue,{marginHorizontal:10, fontSize: 32, color:"#0096BB"}]}>EMLV</Text>
                            <Text style={[styles.bienvenue,{marginHorizontal:10, fontSize: 32,color:"#CF1053"}]}>ESILV</Text>
                            <Text style={[styles.bienvenue,{marginHorizontal:10, fontSize: 32,color:"#F28C51"}]}>IIM</Text>
                        </View>
                        <TouchableOpacity // Use TouchableOpacity instead of View for item 1
                            style={styles.carouselItem}
                            onPress={() => handleViewClick("Liste2")}
                        >
                            <Text style={{fontSize: 32, fontFamily: 'TripRegular',color:"#F18B51", textAlign: 'center'}}>DECOUVREZ LA LISTE ICI</Text>
                        </TouchableOpacity>

                        <Svg height={height} width={width}>
                            <Defs>
                                <LinearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
                                    <Stop offset="0" stopColor="#1B1834" stopOpacity="1" />
                                    <Stop offset="1" stopColor="#F18B51" stopOpacity="1" />
                                </LinearGradient>
                            </Defs>
                            <Rect
                                width="100%"
                                height="100%"
                                fill="url(#gradient)"
                            />
                        </Svg>
                        <Text style={{paddingHorizontal:30,marginTop:20,fontSize: 32, fontFamily: 'TripBold',color:"white", textAlign: 'center'}}>JOURNEE</Text>

                        {/*<Text style={{fontSize: 90, fontFamily: 'BestFont',color:"white", textAlign: 'center',marginTop:-10}}>CHECH-IN</Text>*/}
                        <Text style={{fontSize: 32, fontFamily: 'TripBold',color:"#F18B51", textAlign: 'center'}}>LUNDI 27 NOVEMBRE</Text>

                        {/*<ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.carouselContainer}>*/}
                        {/*    <TouchableOpacity // Use TouchableOpacity instead of View for item 1*/}
                        {/*        style={styles.carouselItem2}*/}
                        {/*    >*/}
                        {/*        <ImageBackground*/}
                        {/*            source={"https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_500/v1/accueil/f1oemarxitblhktyqcaz"} // Assurez-vous que l'URL de l'image est correcte*/}
                        {/*            style={styles.carouselItemImage}*/}
                        {/*            imageStyle={styles.backgroundImage} // appliquer le style d'image ici*/}


                        {/*        >*/}
                        {/*            <Text style={{fontSize: 50, fontFamily: 'TripBold',color:"#F18B51", textAlign: 'center',marginBottom:-10,textShadowColor: '#F18B51',textShadowOffset: { width: -1, height: 1 },textShadowRadius: 5}}>1000</Text>*/}
                        {/*            <Text style={{fontSize: 50, fontFamily: 'TripBold',color:"white", textAlign: 'center'}}>HOT-DOGS</Text>*/}
                        {/*        </ImageBackground>*/}
                        {/*    </TouchableOpacity>*/}
                        {/*    <TouchableOpacity // Use TouchableOpacity instead of View for item 1*/}
                        {/*        style={styles.carouselItem2}*/}
                        {/*    >*/}
                        {/*        <ImageBackground*/}
                        {/*            source={"https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_500/v1/accueil/tmlyuucgt8gvstqcrzfx"} // Assurez-vous que l'URL de l'image est correcte*/}
                        {/*            style={styles.carouselItemImage}*/}
                        {/*            imageStyle={styles.backgroundImage} // appliquer le style d'image ici*/}


                        {/*        >*/}
                        {/*            <Text style={{fontSize: 50, fontFamily: 'TripBold',color:"white", textAlign: 'center',marginBottom:-10}}>PLEINS DE</Text>*/}
                        {/*            <Text style={{fontSize: 50, fontFamily: 'TripBold',color:"#F18B51", textAlign: 'center',textShadowColor: '#F18B51',textShadowOffset: { width: -1, height: 1 },textShadowRadius: 5}}>CADEAUX</Text>*/}
                        {/*        </ImageBackground>*/}
                        {/*    </TouchableOpacity>*/}
                        {/*</ScrollView>*/}

                        <Text style={{fontSize: 28, fontFamily: 'TripBold',color:"#ffffff", textAlign: 'center',marginBottom:30, marginTop:20}}>Les informations arrivent bientôt...</Text>

                        <Svg height={height} width={width}>
                        {/*<Svg height={height} width={width} style={{marginTop:-80}}>*/}
                            <Defs>
                                <LinearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
                                    <Stop offset="0" stopColor="#1B1834" stopOpacity="1" />
                                    <Stop offset="1" stopColor="#F18B51" stopOpacity="1" />
                                </LinearGradient>
                            </Defs>
                            <Rect
                                width="100%"
                                height="100%"
                                fill="url(#gradient)"
                            />
                        </Svg>
                        <Text style={{paddingHorizontal:30,marginTop:20,fontSize: 32, fontFamily: 'TripBold',color:"white", textAlign: 'center'}}>SOIREE</Text>
                        <Image
                            source="https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_500/v1/accueil/ocxl8ddsbs3zovkq73nk"
                            style={{width: 250, height: 80, alignSelf: "center",marginVertical:10}}
                        />
                        {/*<Text style={{fontSize: 90, fontFamily: 'BestFont',color:"white", textAlign: 'center',marginTop:-10}}>CHECH-OUT</Text>*/}
                        <Text style={{fontSize: 32, fontFamily: 'TripBold',color:"#F18B51", textAlign: 'center',marginBottom:-10}}>LUNDI 27 NOVEMBRE</Text>
                        <Text style={{fontSize: 32, fontFamily: 'TripBold',color:"#F18B51", textAlign: 'center',marginBottom:30}}>20H - 02H</Text>
                        <Text style={{fontSize: 32, fontFamily: 'TripBold',color:"#F18B51", textAlign: 'center',marginBottom:30}}>AU BADABOUM</Text>

                        <Svg height="60" width="300" style={{marginBottom:-30,marginTop:-45}}>
                            <Defs>
                                <LinearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
                                    <Stop offset="0" stopColor="#8DC9E6" stopOpacity="1" />
                                    <Stop offset="1" stopColor="#F18B51" stopOpacity="1" />
                                </LinearGradient>
                            </Defs>
                            <SvgText
                                fill="url(#gradient)"
                                fontSize="30"
                                fontWeight="bold"
                                x="50%"
                                y="50%"
                                textAnchor="middle"
                                alignmentBaseline="central"
                                style={styles.bienvenue}
                            >
                                2E SHOTGUN - 11H
                            </SvgText>
                        </Svg>
                        <View style={styles.container}>
                            <View style={styles.timerContainer}>
                                {timerComponents.length ? timerComponents : <TouchableOpacity // Use TouchableOpacity instead of View for item 1
                                    style={[styles.carouselItem,{marginTop:30}]}
                                    onPress={() => handleItemClick("https://www.helloasso.com/associations/liste-bde-celest/evenements/afterwork-celest-check-out-1")}
                                >
                                    <Text style={{fontSize: 32, fontFamily: 'TripRegular',color:"#F18B51", textAlign: 'center'}}>PRENDS TA PLACE</Text>
                                </TouchableOpacity>}
                            </View>
                        </View>


                        <View style={{flexDirection: 'row',marginTop:-200,marginBottom:40}}>
                            <View style={{marginRight:20}}>
                                <Text style={[styles.bienvenue,{fontSize: 42, color:"#F18B51",marginTop: 100,fontFamily: 'TripUltra'}]}>500</Text>
                                <Text style={[styles.bienvenue,{fontSize: 36, color:"white",marginTop: -13,fontFamily: 'TripRegular'}]}>places</Text>
                            </View>
                            <View style={{marginLeft:20}}>
                                <Text style={[styles.bienvenue,{fontSize: 42, color:"#F18B51",marginTop: 100,fontFamily: 'TripUltra'}]}>2</Text>
                                <Text style={[styles.bienvenue,{fontSize: 36, color:"white",marginTop: -13,fontFamily: 'TripRegular'}]}>salles</Text>
                            </View>
                        </View>

                        <Text style={[styles.bienvenue,{fontSize: 42,marginTop:-30, color:"#F18B51",fontFamily: 'TripUltra'}]}>Avec :</Text>
                        <Text style={[styles.bienvenue,{fontSize: 24, color:"white",fontFamily: 'TripRegular'}]}>Une tombola</Text>
                        <Text style={[styles.bienvenue,{fontSize: 24, color:"white",fontFamily: 'TripRegular'}]}>Des consos offertes</Text>
                        <Text style={[styles.bienvenue,{fontSize: 24, color:"white",fontFamily: 'TripRegular'}]}>Des sets DJ</Text>
                        <Text style={[styles.bienvenue,{fontSize: 24, color:"white",fontFamily: 'TripRegular'}]}>Des stands de nourriture</Text>
                        <Text style={[styles.bienvenue,{fontSize: 24, color:"white",fontFamily: 'TripRegular',marginBottom:70}]}>Et pleins d'activités pour vous régaler !</Text>

                        <View style={styles.containervideo}>
                             Autres composants

                            <VideoPlayer videoUrl="https://res.cloudinary.com/dskakdlnn/video/upload/f_auto:video,q_auto/v1/videos/h0bchleshmxtpsjjf047" />

                             Autres composants
                        </View>


                        <Svg height={height} width={width} style={{marginTop:30}}>
                        {/*<Svg height={height} width={width} style={{marginTop:130}}>*/}
                            <Defs>
                                <LinearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
                                    <Stop offset="0" stopColor="#1B1834" stopOpacity="1" />
                                    <Stop offset="1" stopColor="#F18B51" stopOpacity="1" />
                                </LinearGradient>
                            </Defs>
                            <Rect
                                width="100%"
                                height="100%"
                                fill="url(#gradient)"
                            />
                        </Svg>
                        <Text style={{paddingHorizontal:30,marginTop:20,fontSize: 28, fontFamily: 'TripBold',color:"white", textAlign: 'center'}}>TU VEUX EN SAVOIR PLUS SUR NOUS ?</Text>
                        <Text style={{paddingHorizontal:30,marginTop:20,fontSize: 26, fontFamily: 'TripRegular',color:"white", textAlign: 'center'}}>Nos projets,<br></br>Nos membres,<br></br>Nos partenaires,<br></br>Notre ambition...</Text>
                        <TouchableOpacity // Use TouchableOpacity instead of View for item 1
                            style={[styles.carouselItem,{marginTop:30}]}
                            onPress={() => handleViewClick("Liste2")}
                        >
                            <Text style={{fontSize: 32, fontFamily: 'TripRegular',color:"#F18B51", textAlign: 'center'}}>DECOUVREZ LA LISTE ICI</Text>
                        </TouchableOpacity>
                        <Text style={{marginTop:-50,paddingHorizontal:30,fontSize: 20, fontFamily: 'TripRegular',color:"white", textAlign: 'center'}}>by</Text>
                        <Text style={{marginBottom:30,fontSize: 80, fontFamily: 'BestFont',color:"white", textAlign: 'center'}}>CELEST</Text>




                        {/* Autres composants vont ici */}
                    </View>
                </ScrollView>
            </ImageBackground>
        </View>
    );
};

const styles = StyleSheet.create({

    text: {
        fontSize: 24,
        color: 'white',
        marginBottom:200,
    },
    nameText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
    },

    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    leftContainer: {
        flex: 1,
        marginLeft: -25, // Adjust the marginLeft to center the left container
        alignItems: 'center', // Add this line to center the content inside the left container
    },
    dateContainer: {
        flexDirection: 'column', // Ajustement pour afficher les éléments l'un sous l'autre
        alignItems: 'center', // Aligner les éléments au centre horizontalement
        marginTop: 10,
        marginLeft: -10,
    },
    dayText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
    },
    monthText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        textTransform: 'uppercase', // Mettre en majuscule pour afficher les mois en majuscules
    },

    typeText: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },

    rightContainer: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', // Add this line to align the content vertically
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer: {
        alignItems: 'center',
    },
    sportIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        marginTop: 8,
    },
    title: {
        fontSize: 34,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,


    },
    itemContainer: {
        flexDirection: 'row',
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        alignItems: 'center', // Use 'flex-start' to align content at the top
    },
    sportIconContainer: {
        marginTop: -10,
        alignSelf: 'center', // Align the sport icon vertically in the itemTextContainer
    },
    logoImg: {
        width: 40,
        height: 40,
        marginLeft: 15,
    },
    screenContainer: {
        flex: 1,
    },
    imageBackground: {
        flex: 1,
        justifyContent: "center", // Centre le contenu sur l'axe des ordonnées
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    scrollView: {
        flex: 1, // Assurez-vous que ScrollView prend toute la hauteur
    },


    bienvenue: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginTop:40,

    },
    logo: {
        width: 300,
        height: 300,
        marginBottom: -30,
        resizeMode: 'contain',
    },
    carouselItem: {
        maxWidth:250,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor:"#F18B51",
        borderWidth:4,
        paddingHorizontal:10,
        paddingVertical:3,
        marginBottom:80,
    },
    horizontalBar: {
        height: '4px',
        background: 'linear-gradient(to right, #1B1834, #F18B51)',
        width: '100%',
    },
    timerContainer: {
        flexDirection: 'row',  // Aligne les enfants du conteneur horizontalement
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:-80
    },
    carouselItem2: {
        width: 300,
        height: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#110E24',
        borderRadius: 50,
        margin: 10,
    },
    carouselContainer: {
        width: '100%',
        overflowX: 'scroll',
        WebkitOverflowScrolling: 'touch',
    },
    carouselItemImage: {
        width: 300, // et ces deux lignes sont nécessaires pour redimensionner correctement
        height: "100%", // l'image en fonction du conteneur
        justifyContent: 'center', // pour centrer le texte à l'intérieur
        alignItems: 'center', // idem, pour le centrage horizontal

    },
    backgroundImage: {
        borderRadius: 50, // cela ajoutera des coins arrondis à votre image
        opacity: 0.3
        // Notez que si vous avez un borderRadius sur carouselItem, il devrait être de la même valeur ici
    },

    containervideo: {
        flex: 1,
        justifyContent: 'center', // Centre le contenu verticalement
        alignItems: 'center', // Centre le contenu horizontalement
        backgroundColor: '#000', // ou toute autre couleur de fond
        marginBottom:90,
    },
    video: {
        width: 220, // Largeur fixe de la vidéo
        height: 400, // Hauteur fixe de la vidéo
        marginTop:40,
        // Vous pouvez définir d'autres styles pour votre vidéo ici
    },

});

export default ListeScreen;
