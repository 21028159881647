import React from 'react';
import {View, Text, StyleSheet, SafeAreaView, TouchableOpacity, Image, ScrollView, Dimensions} from 'react-native';
import { useNavigation } from '@react-navigation/native';

const BilletScreen = () => {
    const navigation = useNavigation();


    const handleViewClick = (screen) => {
        navigation.navigate('Profil');
    };

    const handleBack = (screen) => {
        navigation.navigate('Profil');
    };
    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835'}]}>
            {/* Header */}
            <View style={styles.header}>
                <TouchableOpacity onPress={handleViewClick}>
                    <Image
                        source={require('../assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            </View>

            <View style={styles.container}>
                <Text style={styles.title}>Billets Screen</Text>
                <Text style={styles.subtitle}>VOUS POURREZ RETROUVER ICI VOS BILLETS POUR LES EVENEMENTS</Text>
            </View>


        </SafeAreaView>

    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{ rotate: '180deg' }],
    },
    headerTitle: {
        fontSize: 20,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    itemContainer: {
        flexDirection: 'row',
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
    },
    leftContainer: {
        flex: 1,
        marginLeft:-25,
    },
    dateContainer: {
        flexDirection: 'column', // Ajustement pour afficher les éléments l'un sous l'autre
        alignItems: 'center', // Aligner les éléments au centre horizontalement
        marginBottom: 5,
        marginLeft:-10,
    },
    dayText: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
    },
    monthText: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
        textTransform: 'uppercase', // Mettre en majuscule pour afficher les mois en majuscules
    },

    typeText: {
        fontSize: 13,
        fontFamily: 'MontserratRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 2,
        marginLeft : -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between', // Pour aligner l'icône à droite du rectangle
        alignItems: 'center',
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer: {
        alignItems: 'center',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight:10,
        marginTop:10,
    },
    title: {
        fontSize: 28,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 0,
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'MontserratRegular',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,


    },
});

export default BilletScreen;
