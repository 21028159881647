import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    StyleSheet,
    SafeAreaView,
    ScrollView,
    Image,
    TouchableOpacity,
    Modal,
    Dimensions,
    Linking
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
const screenHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('window').width;
import { Url} from './global'


const RecapScreen = () => {
    const overlayScrollRef = React.createRef();


    const [carouselItemsEtudiant, setCarouselItemsEtudiant] = useState([]);


    const convertDateToReadableFormat = (inputDate) => {
        const parts = inputDate.split("/");
        const day = parts[0];
        const monthNumber = parseInt(parts[1], 10);
        const months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
        const month = months[monthNumber - 1];
        return `Semaine du ${day} ${month}`;
    };

    useEffect(() => {
        // Effectuer la requête lors du montage du composant
        fetch(Url+'api/recap', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                const sortedData = data.sort((a, b) => {
                    const dateA = new Date(a.date.split("/").reverse().join("-"));
                    const dateB = new Date(b.date.split("/").reverse().join("-"));
                    return dateB - dateA;
                });

                // Trier les éléments au sein de chaque semaine en ordre chronologique
                const sortedDataWithSortedItems = sortedData.map(week => ({
                    ...week,
                    liste: week.liste.sort((a, b) => {
                        const dateA = new Date(a.date.split("/").reverse().join("-"));
                        const dateB = new Date(b.date.split("/").reverse().join("-"));
                        return dateA - dateB;
                    })
                }));

                setCarouselItemsEtudiant(sortedDataWithSortedItems);
            })
            .catch(error => console.error('Erreur lors de la récupération des données:', error));
    }, []);  // Le tableau vide signifie que cet effet s'exécutera une seule fois, similaire à componentDidMount


    const [overlayVisible, setOverlayVisible] = useState(false); // État local pour la visibilité de l'overlay

    const [selectedItem, setSelectedItem] = useState(null); // État pour stocker l'élément sélectionné

    const [overlayScroll, setOverlayScroll] = useState(null);

    const handleItemClick = (link) => {
        if (link) {
            // Vérifie si le lien commence par "http://" ou "https://", sinon ajoute "http://"
            if (!link.startsWith("http://") && !link.startsWith("https://")) {
                link = "http://" + link;
            }

            Linking.openURL(link).catch((err) => {
                console.error('Failed to open URL:', link, err);
            });
        }
    };


    const showOverlay = (item) => {
        setSelectedItem(item);
        setOverlayVisible(true);
        setTimeout(() => {
            if (overlayScroll) {
                overlayScroll.scrollTo({ y: 0, animated: false });
            }
        }, 100);
    };

    const hideOverlay = () => {
        setOverlayVisible(false);
    };

    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
            <View style={styles.container}>
                <Text style={styles.title}>Recap</Text>
                <ScrollView contentContainerStyle={styles.scrollViewContent} showsVerticalScrollIndicator={false}>
                    {carouselItemsEtudiant.map((group) => (
                        <View key={group.date} style={styles.groupContainer}>
                            <Text style={styles.groupDate}>{convertDateToReadableFormat(group.date)}</Text>
                            <View style={styles.carouselContainer}>
                                {group.liste.map((item) => (
                                    <TouchableOpacity key={item.nom} style={styles.carouselItem} onPress={() => showOverlay(item)}>
                                        <Image source={item.image} resizeMode="cover" style={styles.carouselItemImage} />
                                        <View style={{paddingBottom: 10}}>
                                            <Text style={styles.carouselItemText}>{item.nom}</Text>
                                            <Text style={styles.subtitle}>{item.date}</Text>
                                        </View>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </View>
                    ))}
                </ScrollView>

                {/* Overlay */}
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={overlayVisible}
                    onRequestClose={hideOverlay}
                >
                    <View style={styles.overlayContainer}>
                        <View style={styles.scrollContainer}>
                            <ScrollView
                                ref={(ref) => setOverlayScroll(ref)}
                                contentContainerStyle={styles.overlayScrollContent}
                                showsVerticalScrollIndicator={false}>
                            <View style={styles.overlayContent}>
                                {selectedItem && (
                                    <>
                                        <Image source={selectedItem.image} style={styles.overlayImage} resizeMode="cover" />
                                        <Text style={styles.overlayDescription2}>@{selectedItem.credit}</Text>
                                        <Text style={styles.overlayTitle}>{selectedItem.nom}</Text>
                                        <Text style={styles.overlayDate}>{selectedItem.asso}</Text>
                                        <Text style={styles.overlayDate}>{selectedItem.date}</Text>


                                        <Text style={styles.overlayDescription}>{selectedItem.description}</Text>
                                        {selectedItem.ensavoirplus && (
                                            <TouchableOpacity style={styles.ensavoirplus} onPress={() => handleItemClick(selectedItem.ensavoirplus)}>
                                                <Text style={styles.overlayDate}>En Savoir +</Text>
                                        </TouchableOpacity>
                                        )}

                                        {selectedItem.photos && (
                                        <TouchableOpacity style={styles.poletech} onPress={() => handleItemClick(selectedItem.photos)}

                                        ><Text style={styles.overlayDate}>Voir les photos</Text>
                                        </TouchableOpacity>
                                            )}
                                    </>
                                )}
                            </View>
                        </ScrollView>
                        </View>
                        <TouchableOpacity style={styles.closeButton} onPress={hideOverlay}>
                            <Text style={styles.closeButtonText}>X</Text>
                        </TouchableOpacity>
                    </View>
                </Modal>

            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    title: {
        fontSize: 34,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 0,
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
    },
    groupContainer: {
        marginVertical: 20,
    },
    groupDate: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 10,
    },
    carouselContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    carouselItem: {
        width: 170,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#110E24',
        borderRadius: 10,
        marginVertical: 10,
        marginHorizontal: 5,
    },
    carouselItemImage: {
        width: '100%',
        height: 190,
        borderRadius: 10,
    },

    scrollViewContent: {
        flexGrow: 1,
        justifyContent: 'center',
    },

    closeButton: {
        position: 'absolute',
        top: 30,
        right: 15,
        backgroundColor: 'red',
        borderRadius: 30,
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeButtonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 24,
    },

    overlayImage: {
        width: '100%',
        height: screenWidth < 768 ? 200 : 300,  // Condition pour déterminer la hauteur
        borderRadius: 10,
    },

    carouselItemText: {
        color: 'white',
        fontSize: 20,
        fontFamily: 'TripBold',
        marginTop: 5,
        textAlign: 'center', // Centrer le texte
    },
    overlayTitle: {
        color: 'white',
        fontSize: 38, // Augmenter la taille du texte
        fontFamily: 'TripBold',
        marginTop: 10,
        textAlign: 'center', // Centrer le texte
    },
    overlayDate: {
        color: 'white',
        fontSize: 24, // Augmenter la taille du texte
        fontFamily: 'TripRegular',
        marginTop: 5,
        textAlign: 'center', // Centrer le texte
    },
    overlayDescription: {
        color: 'white',
        fontSize: 19, // Augmenter la taille du texte
        fontFamily: 'TripRegular',
        marginTop: 10,
        marginBottom:20,
        marginHorizontal:20,
        textAlign: 'center', // Centrer le texte
    },
    overlayDescription2: {
        color: 'white',
        fontSize: 15, // Augmenter la taille du texte
        fontFamily: 'TripRegular',
        marginHorizontal:20,
        textAlign: 'center', // Centrer le texte
    },
    overlayContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    overlayScrollContent: {
        flexGrow: 1,
        alignItems: 'center',
        width: '100%',
    },

    scrollContainer: {
        width: screenWidth * 0.9,
        maxHeight: screenHeight * 0.9,
        backgroundColor: '#110E24',
        borderRadius: 10,
    },
    overlayContent: {
        width: '100%',
        alignItems: 'center',
        paddingBottom:20,
    },
    ensavoirplus:{
        marginVertical:10,
        backgroundColor:'#F18B51',
        paddingHorizontal:15,
        borderRadius:20,
        paddingBottom:5,
    },
    poletech:{
        marginVertical:10,
        backgroundColor:'#8DC9E6',
        paddingHorizontal:15,
        borderRadius:20,
        paddingBottom:5,
    },
});

export default RecapScreen;
