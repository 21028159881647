import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    TextInput,
    TouchableOpacity,
    StyleSheet,
    SafeAreaView,
    Dimensions,
    Picker,
    Image
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { Url } from './global';

const RegisterScreen = () => {
    const navigation = useNavigation();

    const [equipes, setEquipes] = useState([]);
    const [selectedEquipe, setSelectedEquipe] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [pseudo, setPseudo] = useState('');
    const [selectedEcole, setSelectedEcole] = useState('EMLV');
    const [selectedAnnee, setSelectedAnnee] = useState('A1');
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');  // State for password


    useEffect(() => {
        fetch(Url + 'api/get_equipes/')
            .then(response => response.json())
            .then(data => {
                setEquipes(data);
                // Setting the default value for the team picker to the first item in the list
                if (data.length > 0) setSelectedEquipe(data[0].nom);
            })
            .catch(error => console.error("Error fetching teams:", error));
    }, []);

    const handleRegister = () => {
        const data = {
            equipe: selectedEquipe,
            nom: nom,
            prenom: prenom,
            username: pseudo,
            ecole: selectedEcole,
            annee: selectedAnnee,
            password:password,
        };

        fetch(Url + 'api/add_player/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Registration failed');
                }
                return response.json();
            })
            .then(data => {
                // Successfully registered the player
                navigation.navigate('Login');
            })
            .catch(error => {
                setError('Erreur lors de la création du compte');
                console.error('An error occurred during registration:', error);
            });
    };

    const handleBack = () => {
        navigation.navigate("ChoixConnexion");
    };

    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
            <View style={styles.header}>
                <TouchableOpacity onPress={handleBack}>
                    <Image
                        source={require('./assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            </View>
            <View style={styles.container}>
                <Text style={styles.title}>Créer un compte</Text>

                <Picker
                    selectedValue={selectedEquipe}
                    style={styles.input}
                    onValueChange={(itemValue, itemIndex) => setSelectedEquipe(itemValue)}>
                    {equipes.map((team) => <Picker.Item key={team.id} label={team.nom} value={team.nom} />)}
                </Picker>

                <TextInput
                    style={styles.input}
                    placeholder="Nom"
                    onChangeText={(text) => setNom(text)}
                    value={nom}
                    placeholderTextColor="#9A8FAF"
                />

                <TextInput
                    style={styles.input}
                    placeholder="Prenom"
                    onChangeText={(text) => setPrenom(text)}
                    value={prenom}
                    placeholderTextColor="#9A8FAF"
                />

                <TextInput
                    style={styles.input}
                    placeholder="Pseudo"
                    onChangeText={(text) => setPseudo(text)}
                    value={pseudo}
                    placeholderTextColor="#9A8FAF"
                />

                <Picker
                    selectedValue={selectedEcole}
                    style={styles.input}
                    onValueChange={(itemValue, itemIndex) => setSelectedEcole(itemValue)}>
                    <Picker.Item label="EMLV" value="EMLV" />
                    <Picker.Item label="ESILV" value="ESILV" />
                    <Picker.Item label="IIM" value="IIM" />
                </Picker>

                <Picker
                    selectedValue={selectedAnnee}
                    style={styles.input}
                    onValueChange={(itemValue, itemIndex) => setSelectedAnnee(itemValue)}>
                    <Picker.Item label="A1" value="A1" />
                    <Picker.Item label="A2" value="A2" />
                    <Picker.Item label="A3" value="A3" />
                    <Picker.Item label="A4" value="A4" />
                    <Picker.Item label="A5" value="A5" />
                </Picker>

                <TextInput
                    style={styles.input}
                    placeholder="Mot de passe"
                    onChangeText={(text) => setPassword(text)}
                    value={password}
                    secureTextEntry
                    placeholderTextColor="#9A8FAF"
                />

                {error && <Text style={styles.errorMessage}>{error}</Text>}

                <TouchableOpacity
                    style={[styles.buttonConnexion, { backgroundColor: '#110E24' }]}
                    onPress={handleRegister}>
                    <Text style={styles.buttonText}>S'inscrire</Text>
                </TouchableOpacity>
            </View>
        </SafeAreaView>
    );
};

// You can keep the same styles as the LoginScreen for consistency
const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{ rotate: '180deg' }],
    },
    headerTitle: {
        fontSize: 20,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
        padding: 20,
    },
    title: {
        fontSize: 28,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginBottom: 20,
    },
    input: {
        height: 40,
        width: Dimensions.get('window').width < 350 ? '100%' : 350,
        borderColor: '#15132A',
        borderWidth: 1,
        marginBottom: 20,
        paddingHorizontal: 10,
        color: 'white',
        backgroundColor: '#110E24',
        fontSize: 18,
    },
    buttonConnexion: {
        borderRadius: 20,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        width: 200,
        marginBottom:15,
    },

    buttonText: {
        color: 'white',
        fontSize: 18,
        fontFamily: 'MontserratBold', // Remplacez 'YourCustomFont' par le nom de la police que vous souhaitez utiliser
    },
    errorMessage: {
        color: 'red',
        marginBottom: 20,
    },
});

export default RegisterScreen;
