import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import { useFonts, Montserrat_700Bold, Montserrat_400Regular } from '@expo-google-fonts/montserrat';
import { Asset } from 'expo-asset';  // Importez Asset d'expo-asset
import * as Font from 'expo-font';


// Import your icons
import logoIcon from './assets/icons/accueil/logo.png';
import passeportIcon from './assets/icons/accueil/passeport.png';

// Importez votre image d'arrière-plan
import backgroundImage from './assets/bg/fond_app.png';  // Ajustez ce chemin si nécessaire

const SplashScreen = () => {

    let [fontsLoaded] = Font.useFonts({
        'TripRegular': require('./assets/fonts/TripSans-Regular.ttf'),
        'TripBold': require('./assets/fonts/TripSans-Bold.ttf'),
        'TripUltra': require('./assets/fonts/TripSans-Ultra.ttf'),
        'BestFont': require('./assets/fonts/Best_In_Class.ttf'),
    });

    // État pour vérifier si les images sont chargées
    const [isAssetsLoading, setAssetsLoading] = useState(false);

    // Charger l'image d'arrière-plan avant le montage du composant
    useEffect(() => {
        const preloadAssets = async () => {
            try {
                // Ici, vous pouvez charger autant de ressources que nécessaire
                const images = [backgroundImage, logoIcon, passeportIcon];

                const cacheImages = images.map(image => {
                    return Asset.fromModule(image).downloadAsync();
                });

                await Promise.all(cacheImages);
                setAssetsLoading(true); // Mettre l'état à true lorsque le chargement est terminé
            } catch (err) {
                console.error('Error loading assets', err);
            }
        };

        preloadAssets();
    }, []);

    if (!fontsLoaded || !isAssetsLoading) {
        // Render a loading screen while fonts or images are loading
        return null;
    }

    return (
        <View style={styles.splashScreen}>
            <Text style={styles.title}>TRAVAUX</Text>
            <Text style={styles.subtitle}>L'appli est en rénovation pendant la période des vacances</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    splashScreen: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    logo: {
        width: 400,
        height: 400,
        marginBottom: 20,
        resizeMode: 'contain',
    },
    title: {
        fontSize: 32,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 10,
        fontWeight: 'bold',
    },
    subtitle: {
        fontSize: 24,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 10,
    },
    passeportLogo: {
        width: 50,
        height: 50,
        resizeMode: 'contain',
    },
});

export default SplashScreen;
