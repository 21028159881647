import React, {useCallback, useState, useEffect} from 'react';
import { View, Text, StyleSheet, SafeAreaView, Image, TouchableOpacity, Dimensions, Linking, ScrollView,Picker } from 'react-native';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import Cookies from "js-cookie";
import {useCookies} from "react-cookie";
import { Url} from '../global'


const EcheancesScreen = () => {
    const navigation = useNavigation();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']); // Ajoutez removeCookie
    const [selectedEcole, setSelectedEcole] = useState('');
    const [selectedAnnee, setSelectedAnnee] = useState('');



    const handleViewClick = () => {
        navigation.navigate('Etudiant');
    };

    const typeMapping = {
        'rendu_dvo': 'Rendu DVO',
        'evenement': 'Evenement',
        'formulaire': 'Formulaire',
        'examen':'Examen',
    };



    const [items, setItems] = useState([]);

    const [user, setUser] = useState({
        "nom": "",
        "prenom": "",
        "username": "",
        "ecole": "",
        "annee": "",
        "equipe": "",
        "numero": null,
    });

    const loadItems = (ecole, annee) => {
        fetch(Url+`api/rendu/${ecole}/${annee}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((items) => setItems(items))
            .catch((error) => console.error('There was an error fetching the data!', error));
    };

    useFocusEffect(
        useCallback(() => {
/*
            if (!Cookies.get('accessToken')) {
                setTimeout(() => navigation.navigate("ProfilInvite"), 50);
            }*/

            const token = cookies.accessToken;

            setUser({
                "nom": "",
                "prenom": "",
                "username": "",
                "ecole": "",
                "annee": "",
                "equipe": "",
                "numero": null,
            });


            if (token) {
                fetch(Url+`api/whoami`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        if (response.status !== 200) {
                            if(response.status == 401){
                                removeCookie('accessToken');
                                navigation.navigate('Login');
                                return;
                            }
                            else{
                                navigation.navigate('Associations');
                                return;
                            }


                        }
                        return response.json();
                    })
                    .then((data) => data && setUser(data) && loadItems(data))
                    .catch((error) => console.error('There was an error fetching the data!', error));

            }
        }, [cookies.accessToken, navigation]) // Dépendances
    );

    useEffect(() => {
        if (user.ecole && user.annee) {
            loadItems(user.ecole, user.annee);
        }
    }, [user.ecole, user.annee]);

    const handleItemClick = (link) => {
        if (link) {
            // Vérifie si le lien commence par "http://" ou "https://", sinon ajoute "http://"
            if (!link.startsWith("http://") && !link.startsWith("https://")) {
                link = "http://" + link;
            }

            Linking.openURL(link).catch((err) => {
                console.error('Failed to open URL:', link, err);
            });
        }
    };

    const formatMonth = (dateString) => {
        const months = ["JAN", "FEV", "MAR", "AVR", "MAI", "JUIN", "JUIL", "AOU", "SEP", "OCT", "NOV", "DEC"];

        // Analyser la chaîne de date au format "DD/MM/YYYY"
        const [day, monthNum, year] = dateString.split('/');
        const month = months[parseInt(monthNum, 10) - 1];

        return { day, month };
    };

    const renderArrowIcon = (link) => {
        if (link) {
            return (
                <Image
                    source={require('../assets/icons/header/fleche.png')}
                    style={styles.arrowIcon}
                    resizeMode="contain"
                />
            );
        }
        return null;
    };

    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835'}]}>
            {/* Header */}
            <View style={styles.header}>
                <TouchableOpacity onPress={handleViewClick}>
                    <Image
                        source={require('../assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            </View>

            {/* Content */}
            <ScrollView>
            <View style={styles.container}>
                <Text style={styles.title}>Echéances</Text>
                <View style={styles.container}>
                    <Text style={styles.subtitle}>Ecole : </Text>
                    <Picker
                        selectedValue={selectedEcole}
                        style={[styles.input, { textAlign: 'center', borderRadius: 20 }]}
                        onValueChange={(itemValue) => setSelectedEcole(itemValue)}
                    >
                        <Picker.Item label="" value="" />
                        <Picker.Item label="EMLV" value="EMLV" />
                        <Picker.Item label="ESILV" value="ESILV" />
                        <Picker.Item label="IIM" value="IIM" />
                    </Picker>
                    <Text style={styles.subtitle}>Année :</Text>
                    <Picker
                        selectedValue={selectedAnnee}
                        style={[styles.input, { textAlign: 'center', borderRadius: 20 }]}
                        onValueChange={(itemValue) => setSelectedAnnee(itemValue)}
                    >
                        <Picker.Item label="" value="" />
                        <Picker.Item label="A1" value="A1" />
                        <Picker.Item label="A2" value="A2" />
                        <Picker.Item label="A3" value="A3" />
                        <Picker.Item label="A4" value="A4" />
                        <Picker.Item label="A5" value="A5" />
                    </Picker>
                </View>
                <TouchableOpacity
                    style={styles.viewButton}
                    onPress={() => loadItems(selectedEcole, selectedAnnee)}
                >
                    <Text style={styles.subtitle}>VOIR</Text>
                </TouchableOpacity>

                {items?.map((item, index) => (
                    <TouchableOpacity
                        key={index}
                        onPress={() => handleItemClick(item.link)}
                        style={[styles.itemContainer, { backgroundColor: '#110E24' }]}
                    >
                        <View style={styles.leftContainer}>
                            <View style={styles.dateContainer}>
                                <Text style={styles.dayText}>{formatMonth(item.date).day}</Text>
                                <Text style={styles.monthText}>{formatMonth(item.date).month}</Text>
                            </View>
                        </View>
                        <View style={styles.rightContainer}>
                            <View style={styles.itemTextContainer}>
                                <View style={styles.itemInfoContainer}>
                                    <View style={styles.titleContainer}>
                                        <Text style={styles.typeText}>{typeMapping[item.type]}</Text>
                                    </View>
                                    <Text style={styles.nameText}>{item.name}</Text>
                                </View>
                                {renderArrowIcon(item.link)} {/* Afficher l'icône uniquement s'il y a un lien */}
                            </View>
                        </View>
                    </TouchableOpacity>
                ))}
            </View>
                </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{ rotate: '180deg' }],
    },
    headerTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    itemContainer: {
        flexDirection: 'row',
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
    },
    leftContainer: {
        flex: 1,
        marginLeft:-25,
    },
    dateContainer: {
        flexDirection: 'column', // Ajustement pour afficher les éléments l'un sous l'autre
        alignItems: 'center', // Aligner les éléments au centre horizontalement
        marginBottom: 5,
        marginLeft:-10,
    },
    dayText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
    },
    monthText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        textTransform: 'uppercase', // Mettre en majuscule pour afficher les mois en majuscules
    },

    typeText: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
        flexShrink: 1,
    },
    rightContainer: {
        flex: 2,
        marginLeft : -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between', // Pour aligner l'icône à droite du rectangle
        alignItems: 'center',
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: 1,
    },
    titleContainer: {
        alignItems: 'center',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight:10,
        marginTop:10,
    },
    title: {
        fontSize: 32,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
        marginBottom:5,

    },
    dropdownContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        padding: 10,
    },
    dropdown: {
        height: 50,
        width: 150,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 8,
    },
    viewButton: {
        height: 40,
        width: 100,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        backgroundColor: '#8DC9E6',
        marginVertical: 10,
        marginBottom:20,
        marginTop:-5,
    },
    input: {
        height: 40,
        width: Dimensions.get('window').width < 350 ? '100%' : 350,
        borderColor: '#0D0D0D',
        borderWidth: 1,
        marginBottom: 20,
        paddingHorizontal: 10,
        color: 'white',
        backgroundColor: '#110E24',
        fontSize: 18,
    },


});

export default EcheancesScreen;
