import React from 'react';
import {View, Text, StyleSheet, SafeAreaView, Image, TouchableOpacity} from 'react-native';
import {useNavigation} from "@react-navigation/native";

const PasseportScreen = () => {
    const navigation = useNavigation();

    const handleViewClick = (screen) => {
        navigation.navigate(screen);
    };
    return (
        <SafeAreaView style={[styles.screenContainer, { backgroundColor: '#1B1835' }]}>
            <View style={styles.container}>
                <Text style={styles.text}>Passeport Screen</Text>


            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    text: {
        fontSize: 24,
        color: 'white',
    },
});

export default PasseportScreen;
