import React, {useEffect, useState} from 'react';
import {
    View,
    Text,
    StyleSheet,
    SafeAreaView,
    Image,
    TouchableOpacity,
    ScrollView,
    Linking,
    Dimensions
} from 'react-native';
import {useNavigation, useFocusEffect} from '@react-navigation/native';
import { Url} from '../global'

const FAQScreen = () => {
    const navigation = useNavigation();


    const handleViewClick = (screen) => {
        navigation.navigate(screen);
    };



    // Données d'entrée
    const items = [{name:"BDE", screen:"FAQBDE",logo:"https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_200/v1/faq/vdghci9fcootwvr9gdl5"},{name:'Associations', screen:"FAQAsso", logo:"https://res.cloudinary.com/dskakdlnn/image/upload/v1696573667/faq/rkxjz9zuaslnj2w0wgm3.png"},{name:'Pôle', screen:"FAQPole", logo:"https://res.cloudinary.com/dskakdlnn/image/upload/c_scale,w_200/v1/faq/bqgs7wi0bfgameploizj"}];


    return (
        <SafeAreaView style={[styles.screenContainer, {backgroundColor: '#1B1835'}]}>
            {/* Header */}
            <View style={styles.header}>
                <TouchableOpacity onPress={() => handleViewClick ("Etudiant")}>
                    <Image
                        source={require('../assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            </View>

            {/* Content */}
            <ScrollView>
                <View style={styles.container}>
                    <Text style={styles.title}>FAQ</Text>
                    <Text style={styles.subtitle}>Thèmes</Text>
                    {items.map((item, index) => (
                        <TouchableOpacity
                            key={index}
                            onPress={() => handleViewClick(item.screen)}
                            style={[styles.itemContainer, {backgroundColor: '#110E24'}]}
                        >
                            <View style={styles.leftContainer}>
                                <Image
                                    source={item.logo}
                                    style={styles.logoImg}
                                    resizeMode="contain"
                                />

                            </View>
                            <View style={styles.rightContainer}>
                                <View style={styles.itemTextContainer}>
                                    <View style={styles.itemInfoContainer}>

                                        <Text style={styles.nameText}>{item.name}</Text>

                                    </View>
                                </View>
                            </View>
                        </TouchableOpacity>
                    ))}
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    leftContainer: {
        flex: 1,
        marginLeft: -25, // Adjust the marginLeft to center the left container
        alignItems: 'center', // Add this line to center the content inside the left container
    },
    dateContainer: {
        flexDirection: 'column', // Ajustement pour afficher les éléments l'un sous l'autre
        alignItems: 'center', // Aligner les éléments au centre horizontalement
        marginTop: 10,
        marginLeft: -10,
    },
    dayText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
    },
    monthText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        textTransform: 'uppercase', // Mettre en majuscule pour afficher les mois en majuscules
    },

    typeText: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', // Add this line to align the content vertically
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer: {
        alignItems: 'center',
    },
    sportIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        marginTop: 8,
    },
    title: {
        fontSize: 32,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,


    },
    itemContainer: {
        flexDirection: 'row',
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        alignItems: 'center', // Use 'flex-start' to align content at the top
    },
    sportIconContainer: {
        marginTop: -10,
        alignSelf: 'center', // Align the sport icon vertically in the itemTextContainer
    },
    logoImg: {
        width: 40,
        height: 40,
        marginLeft: 15,
    },


});

export default FAQScreen;
