import React, {useEffect, useState} from 'react';
import {
    View,
    Text,
    StyleSheet,
    SafeAreaView,
    Image,
    TouchableOpacity,
    ScrollView,
    Linking,
    Dimensions
} from 'react-native';
import {useNavigation, useFocusEffect} from '@react-navigation/native';
import { Url} from '../global'

const FAQBDEScreen = () => {
    const navigation = useNavigation();

    const handleViewClick = () => {
        navigation.navigate('FAQ');
    };
    const [openItemIndex, setOpenItemIndex] = useState(null);


    // Données d'entrée
    const items = [{name:"Comment participer à une soirée BDE ?",reponse:"Il faut prendre une place sur la billetterie qui est disponible sur l'app et notre insta. Pour certains événements, nous tenons un stand en Rue Haute pour vendre des places\n\nATTENTION : Les places partent vite, on te conseille de les prendre à l'ouverture de la billetterie"},
        {name:"Qu’est ce qu’un BDE ?",reponse:"Le BDE c'est un groupe d'étudiant du Pôle chargé d'apporter de l'ambiance, d'organiser des événements marquants et de garantir que ton année soit aussi enrichissante que divertissante"},
        {name:"Comment intégrer le BDE",reponse:"En fonction de nos besoins, on pourra ouvrir certains recrutements au cours de l'année. Pour être au courant, pense à suivre nos réseaux !\n\nTu peux aussi créer ta liste pour l'année prochaine"},
        {name:"Quelles sont les missions d’un BDE ?",reponse:"Les missions sont très variées :\n\n  - Organiser des soirées\n  - Créer des événements étudiants\n  - T'aider dans ta vie au Pôle\n  -Créer un lien entre les assos"},
        {name:"Quelle est la fréquence des événéments organisés par le BDE ?",reponse:"On vous prépare plein d'événements ! Entre les journées, les soirées et tous les autres projets, tu pourras profiter avec Celest au moins une fois par mois !"},
        {name:"Comment être informé des événements et projets du BDE ?",reponse:"En nous suivant sur Instagram et en consultant régulièrement l'app.\n\nSi tu es sur iOS, n'oublie pas d'ajouter l'app à tes widgets pour pouvoir recevoir les notifications"},
        {name:"J’ai une entreprise ou une association et je souhaite faire un partenariat",reponse:"Pour tout partenariat, n'hésitez pas à nous contacter sur le mail suivant : ... "},

    ];



    return (
        <SafeAreaView style={[styles.screenContainer, {backgroundColor: '#1B1835'}]}>
            {/* Header */}
            <View style={styles.header}>
                <TouchableOpacity onPress={handleViewClick}>
                    <Image
                        source={require('../assets/icons/header/fleche.png')}
                        style={[styles.backButton, styles.rotate180]}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            </View>

            {/* Content */}
            <ScrollView>
                <View style={styles.container}>
                    <Text style={styles.title}>FAQ - BDE</Text>
                    {items.map((item, index) => (
                        <TouchableOpacity
                            key={index}
                            style={[styles.itemContainer, {backgroundColor: '#110E24'}]}
                            onPress={() => {
                                // Toggle the open state
                                if (openItemIndex === index) {
                                    setOpenItemIndex(null);
                                } else {
                                    setOpenItemIndex(index);
                                }
                            }}
                        >
                            <View style={styles.questionContainer}>
                                <Text style={styles.simplifiedText}>{item.name}</Text>
                                <Image
                                    source={require('../assets/icons/plus.png')}
                                    style={styles.arrowIcon}
                                    resizeMode="contain"
                                />
                            </View>

                            {/* Conditionally render the response if this item is open */}
                            {openItemIndex === index && (
                                <Text style={styles.simplifiedText2}>{item.reponse}</Text>
                            )}
                        </TouchableOpacity>
                    ))}
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight: 10,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 24,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    leftContainer: {
        flex: 1,
        marginLeft: -25, // Adjust the marginLeft to center the left container
        alignItems: 'center', // Add this line to center the content inside the left container
    },
    dateContainer: {
        flexDirection: 'column', // Ajustement pour afficher les éléments l'un sous l'autre
        alignItems: 'center', // Aligner les éléments au centre horizontalement
        marginTop: 10,
        marginLeft: -10,
    },
    dayText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
    },
    monthText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        textTransform: 'uppercase', // Mettre en majuscule pour afficher les mois en majuscules
    },

    typeText: {
        fontSize: 16,
        fontFamily: 'TripRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 28,
        fontFamily: 'TripBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 1, // Add this
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1, // Add this
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginHorizontal:5,
    },
    titleContainer: {
        alignItems: 'center',
    },
    sportIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        marginTop: 8,
    },
    title: {
        fontSize: 32,
        fontFamily: 'TripBold',
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
        flexShrink: 1 // Add this line
    },
    itemContainer: {
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        alignItems: 'center', // Use 'flex-start' to align content at the top
    },
    sportIconContainer: {
        marginTop: -10,
        alignSelf: 'center', // Align the sport icon vertically in the itemTextContainer
    },
    simplifiedContainer: {
        padding: 5,
    },
    simplifiedText: {
        width: '90%',
        fontSize: 24,
        fontFamily: 'TripRegular',
        color: 'white',
    },
    simplifiedText2: {
        paddingTop:15,
        fontSize: 22,
        fontFamily: 'TripRegular',
        color: 'white',
        textAlign: 'center',
    },
    questionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    answerContainer: {
        marginTop: 10, // Add some margin for visual separation
    },


});

export default FAQBDEScreen;
