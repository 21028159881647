import React, {useRef, useState, useEffect, useCallback} from 'react';
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    Dimensions
} from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';

const TeamScreen = () => {
    const navigation = useNavigation();

    const [players, setPlayers] = useState([]);
    const teams = ["Tonnerre", "Eclipse", "Rafale", "Tempete"];
    const [selectedTeam, setSelectedTeam] = useState(teams[0]);

    useFocusEffect(
        useCallback(() => {
            fetch('https://django-2xha.onrender.com/api/get_players_equipes/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => setPlayers(data))
                .catch((error) => console.error('Error fetching players!', error));
        }, [])
    );

    const handleTeamClick = (team) => {
        setSelectedTeam(team);
    };

    const renderTeams = () => {
        return (
            <View style={styles.container}>
                {teams.map((team) => (
                    <TouchableOpacity
                        key={team}
                        onPress={() => handleTeamClick(team)}
                        style={[
                            styles.rectangle,
                            selectedTeam === team && styles.selectedRectangle,
                        ]}
                    >
                        <Text style={styles.elementText}>{team}</Text>
                    </TouchableOpacity>
                ))}
            </View>
        );
    };

    const renderPlayers = () => {
        const teamPlayers = players.filter(player => player.equipe === selectedTeam);
        return (
            <View style={styles.container2}>
                <Text style={styles.title}>Membres de {selectedTeam}</Text>
                {teamPlayers.map((player) => (
                    <TouchableOpacity
                        key={player.username}
                        style={[styles.itemContainer2, { backgroundColor: '#110E24' }]}
                    >
                        <Text style={styles.nameText2}>{player.username} #{player.numero}</Text>
                    </TouchableOpacity>
                ))}
            </View>
        );
    };

    return (
        <ScrollView style={styles.screenContainer}>
            <View style={styles.header}>
                <Text style={styles.title}>Equipes</Text>
            </View>

            {renderTeams()}
            {selectedTeam && renderPlayers()}
        </ScrollView>
    );
};
const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: '#1B1835',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    backButton: {
        width: 30,
        height: 30,
        marginRight: 10,
        cursor: 'pointer',
    },
    rotate180: {
        transform: [{rotate: '180deg'}],
    },
    headerTitle: {
        fontSize: 20,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        marginLeft: 20,
    },
    scrollViewContainer: {
        flexGrow: 1,
        marginBottom:-120,
    },
    centerScrollView: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    scrollViewContent: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    rectangle: {
        backgroundColor: '#110E24',
        height: 40,
        borderRadius: 25,
        marginVertical: 10,
        marginHorizontal: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    elementText: {
        fontSize: 16,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginRight: 8,
        marginLeft: 8,
    },
    title: {
        fontSize: 28,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
        flex: 1,
    },
    sectionTitle: {
        fontSize: 20,
        fontFamily: 'MontserratBold',
        color: 'white',
        textAlign: 'center',
    },
    sportIconContainer: {
        marginTop: -10,
        alignSelf: 'center', // Align the sport icon vertically in the itemTextContainer
    },
    sportIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        marginTop: 8,
    },


    leftContainer: {
        flex: 1,
        marginLeft: -25, // Adjust the marginLeft to center the left container
        alignItems: 'center', // Add this line to center the content inside the left container
    },


    typeText: {
        fontSize: 13,
        fontFamily: 'MontserratRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', // Add this line to align the content vertically
    },
    itemInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer: {
        alignItems: 'center',
    },

    subtitle: {
        fontSize: 18,
        fontFamily: 'MontserratRegular',
        color: 'white',
        textAlign: 'center',
    },
    itemContainer: {
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        alignItems: 'flex-start', // Use 'flex-start' to align content at the top
    },


    container2: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1835',
    },
    itemContainer2: {
        flexDirection: 'row',
        width: Dimensions.get('window').width < 500 ? '90%' : 500,
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
    },
    leftContainer2: {
        flex: 1,
        marginLeft: -25,
    },


    typeText2: {
        fontSize: 13,
        fontFamily: 'MontserratRegular',
        color: 'white',
        marginBottom: 5,
    },

    nameText2: {
        fontSize: 24,
        fontFamily: 'MontserratBold',
        color: 'white',
        marginBottom: 5,
    },
    rightContainer2: {
        flex: 2,
        marginLeft: -10,
    },
    itemTextContainer2: {
        flexDirection: 'row',
        justifyContent: 'space-between', // Pour aligner l'icône à droite du rectangle
        alignItems: 'center',
    },
    itemInfoContainer2: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleContainer2: {
        alignItems: 'center',
    },
    arrowIcon: {
        width: 25,
        height: 25,
        marginRight: 10,
        marginTop: 10,
    },
    logoImg: {
        width: 80,
        height: 80,
        marginLeft: 25,
    },
    selectedRectangle: {
        backgroundColor: '#8ECAE6', // Light blue background for selected items
    },


});

export default TeamScreen;
